<template>
  <svg
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3796_4900)">
      <path
        d="M0 10.3347C0 5.15467 4.2 0.954666 9.38 0.954666H38.63C43.81 0.954666 48.01 5.15467 48.01 10.3347V39.5847C48.01 44.7647 43.81 48.9647 38.63 48.9647H9.38C4.2 48.9647 0 44.7647 0 39.5847V10.3347Z"
        fill="#000000"
      />
      <path
        d="M16.8701 18.6547H10.8701V37.6547H16.8701V18.6547Z"
        fill="white"
      />
      <path
        d="M10.8701 13.6147C10.8701 11.9347 12.0701 10.6547 13.8701 10.6547C15.6701 10.6547 16.8001 11.9347 16.8701 13.6147C16.8701 15.2947 15.7501 16.6547 13.8701 16.6547C11.9901 16.6547 10.8701 15.2947 10.8701 13.6147Z"
        fill="white"
      />
      <path
        d="M38.8701 37.6547H32.8701V27.6547C32.8701 25.6547 31.8701 23.6547 29.3701 23.6147H29.2901C26.8701 23.6147 25.8701 25.6747 25.8701 27.6547V37.6547H19.8701V18.6547H25.8701V21.2147C25.8701 21.2147 27.8001 18.6547 31.6801 18.6547C35.5601 18.6547 38.8701 21.3847 38.8701 26.9147V37.6547Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3796_4900">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="translate(0 0.954666)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
