<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.6875C0 2.09867 2.09867 0 4.6875 0H19.3125C21.9013 0 24 2.09867 24 4.6875V19.3125C24 21.9013 21.9013 24 19.3125 24H4.6875C2.09867 24 0 21.9013 0 19.3125V4.6875Z"
      fill="#000000"
    />
    <path
      d="M11.9394 5.25C5.73577 5.25 5.25 5.73577 5.25 11.9394V12.0606C5.25 18.2642 5.73577 18.75 11.9394 18.75H12.0606C18.2642 18.75 18.75 18.2642 18.75 12.0606V12C18.75 5.74005 18.26 5.25 12 5.25H11.9394ZM10.65 6.6H13.35C17.106 6.6 17.4 6.89396 17.4 10.65V13.35C17.4 17.106 17.106 17.4 13.35 17.4H10.65C6.89396 17.4 6.6 17.106 6.6 13.35V10.65C6.6 6.89396 6.89396 6.6 10.65 6.6ZM15.373 7.95C15.0001 7.95101 14.699 8.25404 14.7 8.62698C14.701 8.99992 15.004 9.30101 15.377 9.3C15.7499 9.29899 16.051 8.99596 16.05 8.62302C16.049 8.25009 15.746 7.94899 15.373 7.95ZM11.9921 8.625C10.1284 8.62939 8.62061 10.1442 8.625 12.0079C8.62939 13.8716 10.1442 15.3794 12.0079 15.375C13.8716 15.3706 15.3794 13.8558 15.375 11.9921C15.3706 10.1284 13.8558 8.62061 11.9921 8.625ZM11.9954 9.975C13.1135 9.9723 14.0223 10.8772 14.025 11.9954C14.0274 13.1135 13.1228 14.0223 12.0046 14.025C10.8865 14.0277 9.9777 13.1231 9.975 12.0046C9.9723 10.8865 10.8772 9.9777 11.9954 9.975Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
