<template>
  <component :is="currentIcon" v-bind="iconColor" class="icon" />
</template>

<script lang="ts">
import { computed } from "vue";
import type { PropType, Component } from "vue";
import ArrowIcon from "~/components/icons/simple/ArrowIcon.vue";
import LanguageIcon from "~/components/icons/simple/LanguageIcon.vue";
import PhoneIcon from "~/components/icons/simple/PhoneIcon.vue";
import VkIcon from "~/components/icons/social/VkIcon.vue";
import FacebookIcon from "~/components/icons/social/FacebookIcon.vue";
import InstagramIcon from "~/components/icons/social/InstagramIcon.vue";
import AppStoreIcon from "~/components/icons/applicationsStores/AppStoreIcon.vue";
import AppGalleryIcon from "~/components/icons/applicationsStores/AppGalleryIcon.vue";
import GooglePlayIcon from "~/components/icons/applicationsStores/GooglePlayIcon.vue";
import StarIcon from "~/components/icons/simple/StarIcon.vue";
import AllsportsIcon from "~/components/icons/simple/AllsportsIcon.vue";
import DoneIcon from "~/components/icons/simple/DoneIcon.vue";
import CrossIcon from "~/components/icons/simple/CrossIcon.vue";
import FilterIcon from "~/components/icons/simple/FilterIcon.vue";
import SearchIcon from "~/components/icons/simple/SearchIcon.vue";
import DownloadIcon from "~/components/icons/simple/DownloadIcon.vue";
import BackIcon from "~/components/icons/simple/BackIcon.vue";
import MenuIcon from "~/components/icons/simple/MenuIcon.vue";
import LocationIcon from "~/components/icons/simple/LocationIcon.vue";
import EuroIcon from "~/components/icons/simple/EuroIcon.vue";
import AccessIcon from "~/components/icons/simple/AccessIcon.vue";
import PersonIcon from "~/components/icons/simple/PersonIcon.vue";
import CellPhoneIcon from "~/components/icons/simple/CellPhoneIcon.vue";
import DumbbellIcon from "~/components/icons/simple/DumbbellIcon.vue";
import PersonsIcon from "~/components/icons/simple/PersonsIcon.vue";
import HandshakeIcon from "~/components/icons/simple/HandshakeIcon.vue";
import LogoAllsports from "~/components/icons/logo/LogoAllsports.vue";
import LogoSportbenefit from "~/components/icons/logo/LogoSportbenefit.vue";
import DoubleArrowIcon from "~/components/icons/simple/DoubleArrowIcon.vue";
import InfoIcon from "~/components/icons/simple/InfoIcon.vue";
import LinkedInIcon from "~/components/icons/social/LinkedInIcon.vue";
import FitnessIcon from "~/components/icons/simple/FitnessIcon.vue";
import AtlRouteIcon from "~/components/icons/simple/AtlRouteIcon.vue";
import EventIcon from "~/components/icons/simple/EventIcon.vue";
import PauseIcon from "~/components/icons/simple/PauseIcon.vue";
import ListPointIcon from "~/components/icons/simple/ListPointIcon.vue";
import PeopleGroupIcon from "~/components/icons/simple/PeopleGroupIcon.vue";
import PeopleGroupPlusIcon from "~/components/icons/simple/PeopleGroupPlusIcon.vue";
import CodeIcon from "~/components/icons/simple/CodeIcon.vue";
import VolunteerIcon from "~/components/icons/simple/VolunteerIcon.vue";
import EmergencyIcon from "~/components/icons/simple/EmergencyIcon.vue";
import DiversityIcon from "~/components/icons/simple/DiversityIcon.vue";
import InventoryIcon from "~/components/icons/simple/InventoryIcon.vue";
import TrendingUpIcon from "~/components/icons/simple/TrendingUpIcon.vue";
import SocialLeaderboard from "~/components/icons/simple/SocialLeaderboard.vue";
import CircleInfo from "~/components/icons/simple/CircleInfo.vue";
import CircleCheck from "~/components/icons/simple/CircleCheck.vue";
import CircleXMark from "~/components/icons/simple/CircleXMark.vue";
import LineIcon from "~/components/icons/simple/LineIcon.vue";

type IconsMap = {
  [key: string]: Component;
};

const SIMPLE_ICONS: IconsMap = {
  "logo-allsports": LogoAllsports,
  "logo-sportbenefit": LogoSportbenefit,
  allSports: AllsportsIcon,
  arrow: ArrowIcon,
  doubleArrow: DoubleArrowIcon,
  lang: LanguageIcon,
  phone: PhoneIcon,
  star: StarIcon,
  done: DoneIcon,
  cross: CrossIcon,
  filter: FilterIcon,
  search: SearchIcon,
  download: DownloadIcon,
  back: BackIcon,
  menu: MenuIcon,
  location: LocationIcon,
  euro: EuroIcon,
  access: AccessIcon,
  person: PersonIcon,
  persons: PersonsIcon,
  cellPhone: CellPhoneIcon,
  dumbbell: DumbbellIcon,
  handshake: HandshakeIcon,
  info: InfoIcon,
  fitness: FitnessIcon,
  altRoute: AtlRouteIcon,
  event: EventIcon,
  pause: PauseIcon,
  listPoint: ListPointIcon,
  peopleGroup: PeopleGroupIcon,
  peopleGroupPlus: PeopleGroupPlusIcon,
  code: CodeIcon,
  volunteer: VolunteerIcon,
  emergency: EmergencyIcon,
  diversity: DiversityIcon,
  inventory: InventoryIcon,
  trendingUpIcon: TrendingUpIcon,
  socialLeaderboard: SocialLeaderboard,
  "circle-info": CircleInfo,
  "circle-check": CircleCheck,
  "circle-x-mark": CircleXMark,
  line: LineIcon,
};
const SOCIAL_NETWORK_ICONS: IconsMap = {
  vk: VkIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
};
const APPLICATIONS_STORES_ICONS: IconsMap = {
  appStore: AppStoreIcon,
  appGallery: AppGalleryIcon,
  googlePlay: GooglePlayIcon,
};

const SIMPLE_ICONS_LIST = Object.keys(SIMPLE_ICONS);
const SOCIAL_NETWORK_ICONS_LIST = Object.keys(SOCIAL_NETWORK_ICONS);
const APPLICATIONS_STORES_ICONS_LIST = Object.keys(APPLICATIONS_STORES_ICONS);

export const AVAILABLE_ICONS_LIST = [
  ...SIMPLE_ICONS_LIST,
  ...SOCIAL_NETWORK_ICONS_LIST,
  ...APPLICATIONS_STORES_ICONS_LIST,
];

export default defineNuxtComponent({
  props: {
    color: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    icon: {
      type: String as PropType<string>,
      required: true,
      validator: (val: string) => {
        return AVAILABLE_ICONS_LIST.includes(val);
      },
    },
  },

  setup(props) {
    const currentIcon = computed(
      () =>
        SIMPLE_ICONS[props.icon] ||
        SOCIAL_NETWORK_ICONS[props.icon] ||
        APPLICATIONS_STORES_ICONS[props.icon]
    );
    const iconColor = computed(() => {
      const colorProperty = "fill";

      return {
        [colorProperty]: props.color,
      };
    });

    return {
      currentIcon,
      iconColor,
    };
  },
});
</script>
