<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_697_2526)">
      <path
        d="M7.97205 16.0053L7.50733 15.6069C6.86667 15.0705 1.27271 10.2393 1.27271 6.70545C1.27271 3.00551 4.27211 0.00610352 7.97205 0.00610352C11.672 0.00610352 14.6714 3.00551 14.6714 6.70545C14.6714 10.2394 9.07742 15.0705 8.43942 15.6096L7.97205 16.0053ZM7.97205 1.4547C5.07352 1.45798 2.72461 3.80689 2.72133 6.70542C2.72133 8.92548 6.16296 12.472 7.97205 14.0952C9.78117 12.4713 13.2228 8.92279 13.2228 6.70542C13.2195 3.80689 10.8706 1.45801 7.97205 1.4547Z"
      />
      <path
        d="M7.97197 9.36117C6.50534 9.36117 5.31641 8.17224 5.31641 6.70561C5.31641 5.23899 6.50534 4.05005 7.97197 4.05005C9.43859 4.05005 10.6275 5.23899 10.6275 6.70561C10.6275 8.17224 9.43862 9.36117 7.97197 9.36117ZM7.97197 5.3778C7.23866 5.3778 6.64419 5.97227 6.64419 6.70558C6.64419 7.43889 7.23866 8.03336 7.97197 8.03336C8.70528 8.03336 9.29975 7.43889 9.29975 6.70558C9.29975 5.97227 8.70531 5.3778 7.97197 5.3778Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
