<template>
  <TheHeader />
  <main class="main">
    <div class="main-container">
      <NotFoundPage />
    </div>
  </main>
</template>

<script lang="ts">
import NotFoundPage from "~/components/sections/NotFoundPage.vue";
import TheHeader from "~/components/page/TheHeader.vue";

export default defineNuxtComponent({
  components: { TheHeader, NotFoundPage },
});
</script>
