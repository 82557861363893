<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.001 17.9167C8.90611 17.9167 7.87691 17.7089 6.91347 17.2933C5.95001 16.8778 5.11194 16.3138 4.39926 15.6015C3.68656 14.8891 3.12235 14.0514 2.70661 13.0884C2.29088 12.1253 2.08301 11.0964 2.08301 10.0014C2.08301 8.90647 2.29079 7.87728 2.70634 6.91383C3.1219 5.95037 3.68586 5.11231 4.39822 4.39963C5.11059 3.68693 5.94829 3.12272 6.91132 2.70698C7.87433 2.29124 8.90331 2.08337 9.99826 2.08337C11.0932 2.08337 12.1224 2.29115 13.0858 2.70671C14.0493 3.12226 14.8874 3.68622 15.6 4.39858C16.3127 5.11096 16.877 5.94866 17.2927 6.91169C17.7084 7.8747 17.9163 8.90368 17.9163 9.99863C17.9163 11.0936 17.7085 12.1228 17.293 13.0862C16.8774 14.0497 16.3135 14.8877 15.6011 15.6004C14.8887 16.3131 14.051 16.8773 13.088 17.2931C12.125 17.7088 11.096 17.9167 10.001 17.9167ZM9.99965 16.6667C11.8608 16.6667 13.4372 16.0209 14.7288 14.7292C16.0205 13.4375 16.6663 11.8611 16.6663 10C16.6663 8.13891 16.0205 6.56252 14.7288 5.27085C13.4372 3.97919 11.8608 3.33335 9.99965 3.33335C8.13854 3.33335 6.56215 3.97919 5.27049 5.27085C3.97882 6.56252 3.33299 8.13891 3.33299 10C3.33299 11.8611 3.97882 13.4375 5.27049 14.7292C6.56215 16.0209 8.13854 16.6667 9.99965 16.6667Z"
      fill="#C8CAD0"
    />
    <path
      d="M6.88154 9.85272L8.81745 11.7886L13.1027 7.50335C13.2234 7.38263 13.3698 7.32227 13.5418 7.32227C13.7138 7.32227 13.8602 7.38263 13.9809 7.50335C14.1016 7.62407 14.1607 7.77177 14.158 7.94645C14.1553 8.12113 14.0963 8.26616 13.9809 8.38154L9.34468 13.0177C9.19404 13.1684 9.0183 13.2437 8.81745 13.2437C8.61659 13.2437 8.44084 13.1684 8.2902 13.0177L6.00335 10.7309C5.88263 10.6102 5.82227 10.4638 5.82227 10.2918C5.82227 10.1198 5.88263 9.97345 6.00335 9.85272C6.12407 9.73199 6.27177 9.67296 6.44645 9.67564C6.62113 9.67831 6.76616 9.73734 6.88154 9.85272Z"
      fill="#9295A0"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
