<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M6.5767 16.0254L11.6158 21.0645C11.9028 21.3515 12.0449 21.7064 12.0423 22.1293C12.0396 22.5523 11.8948 22.9073 11.6079 23.1942C11.3209 23.4812 10.9673 23.6233 10.547 23.6207C10.1267 23.618 9.77309 23.4759 9.48613 23.1942L3.3528 17.0688C3.20351 16.9196 3.09444 16.7539 3.0256 16.5717C2.95677 16.3896 2.92236 16.1991 2.92236 16C2.92236 15.801 2.95677 15.6104 3.0256 15.4283C3.09444 15.2461 3.20351 15.0804 3.3528 14.9312L9.48613 8.79784C9.79 8.49397 10.1492 8.34204 10.5637 8.34204C10.9782 8.34204 11.3373 8.49397 11.6412 8.79784C11.9451 9.10171 12.097 9.46221 12.097 9.87934C12.097 10.2965 11.9451 10.657 11.6412 10.9609L6.5767 16.0254ZM25.4231 15.9746L20.384 10.9355C20.097 10.6486 19.9549 10.2936 19.9575 9.87067C19.9602 9.44772 20.105 9.09276 20.3919 8.80581C20.6789 8.51885 21.0325 8.3767 21.4528 8.37934C21.8731 8.38201 22.2267 8.52416 22.5137 8.80581L28.647 14.9312C28.7963 15.0804 28.9053 15.2461 28.9742 15.4283C29.043 15.6104 29.0774 15.801 29.0774 16C29.0774 16.1991 29.043 16.3896 28.9742 16.5717C28.9053 16.7539 28.7963 16.9196 28.647 17.0688L22.5137 23.2022C22.2098 23.506 21.8548 23.6538 21.4488 23.6453C21.0428 23.6368 20.6878 23.4807 20.384 23.1768C20.0801 22.8729 19.9282 22.5124 19.9282 22.0953C19.9282 21.6782 20.0801 21.3176 20.384 21.0138L25.4231 15.9746Z"
      fill="#00114E"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "PhoneIcon",
};
</script>

<style scoped></style>
