<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_713_8624)">
      <path
        d="M14.8793 2.95401L5.66666 12.166C5.60472 12.2282 5.53112 12.2775 5.45006 12.3112C5.36901 12.3449 5.2821 12.3622 5.19433 12.3622C5.10656 12.3622 5.01966 12.3449 4.9386 12.3112C4.85755 12.2775 4.78394 12.2282 4.722 12.166L1.15933 8.60001C1.09739 8.53782 1.02378 8.48848 0.942726 8.45481C0.861672 8.42114 0.774766 8.40381 0.686997 8.40381C0.599228 8.40381 0.512322 8.42114 0.431267 8.45481C0.350212 8.48848 0.276603 8.53782 0.214664 8.60001C0.152479 8.66194 0.103137 8.73555 0.0694696 8.81661C0.0358019 8.89767 0.0184708 8.98457 0.0184708 9.07234C0.0184708 9.16011 0.0358019 9.24701 0.0694696 9.32807C0.103137 9.40912 0.152479 9.48273 0.214664 9.54467L3.77866 13.108C4.15463 13.4833 4.66413 13.694 5.19533 13.694C5.72653 13.694 6.23603 13.4833 6.612 13.108L15.824 3.89801C15.8861 3.83608 15.9353 3.76251 15.9689 3.68152C16.0026 3.60052 16.0199 3.5137 16.0199 3.42601C16.0199 3.33832 16.0026 3.25149 15.9689 3.17049C15.9353 3.0895 15.8861 3.01593 15.824 2.95401C15.7621 2.89182 15.6884 2.84248 15.6074 2.80881C15.5263 2.77514 15.4394 2.75781 15.3517 2.75781C15.2639 2.75781 15.177 2.77514 15.0959 2.80881C15.0149 2.84248 14.9413 2.89182 14.8793 2.95401Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
