<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.7897 33.4818C17.3574 35.0495 19.2611 35.8334 21.5007 35.8334C23.7402 35.8334 25.6439 35.0495 27.2116 33.4818C28.7793 31.9141 29.5632 30.0105 29.5632 27.7709C29.5632 25.5313 28.7793 23.6276 27.2116 22.0599C25.6439 20.4922 23.7402 19.7084 21.5007 19.7084C19.2611 19.7084 17.3574 20.4922 15.7897 22.0599C14.222 23.6276 13.4382 25.5313 13.4382 27.7709C13.4382 30.0105 14.222 31.9141 15.7897 33.4818ZM21.5007 30.1896L18.1861 32.698L19.4402 28.6219L16.1257 26.248H20.2017L21.5007 21.948L22.7996 26.248H26.8757L23.5611 28.6219L24.8152 32.698L21.5007 30.1896Z"
      fill="#00114E"
    />
    <path
      d="M13.3934 37.625C10.676 37.625 8.36176 36.6695 6.45065 34.7584C4.53954 32.8473 3.58398 30.533 3.58398 27.8157C3.58398 25.2476 4.44249 23.0304 6.15951 21.1641C7.87652 19.2978 10.0191 18.2601 12.5871 18.0511L5.37565 3.58337H17.9173L21.5007 10.75L25.084 3.58337H37.6256L30.459 17.9615C32.9972 18.2004 35.1248 19.253 36.8418 21.1193C38.5588 22.9856 39.4173 25.2028 39.4173 27.7709C39.4173 30.5181 38.4618 32.8473 36.5507 34.7584C34.6395 36.6695 32.3104 37.625 29.5632 37.625C29.2944 37.625 29.0182 37.6176 28.7345 37.6026C28.4508 37.5877 28.1746 37.5504 27.9059 37.4907C29.5482 36.4157 30.8322 35.0197 31.7579 33.3026C32.6836 31.5856 33.1465 29.7417 33.1465 27.7709C33.1465 24.516 32.0192 21.7613 29.7647 19.5068C27.5102 17.2523 24.7555 16.125 21.5007 16.125C18.2458 16.125 15.4911 17.2523 13.2366 19.5068C10.9821 21.7613 9.85482 24.516 9.85482 27.7709C9.85482 29.8014 10.2729 31.7125 11.109 33.5042C11.9451 35.2959 13.259 36.6247 15.0507 37.4907C14.7819 37.5504 14.5057 37.5877 14.222 37.6026C13.9383 37.6176 13.6621 37.625 13.3934 37.625Z"
      fill="#94A2BC"
    />
  </svg>
</template>
