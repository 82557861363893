<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_697_2552)">
      <path
        d="M15.3333 3.1666H13.5093C13.3662 3.69308 13.0539 4.15786 12.6204 4.48922C12.187 4.82057 11.6566 5.0001 11.111 5.0001C10.5654 5.0001 10.035 4.82057 9.60155 4.48922C9.16812 4.15786 8.85576 3.69308 8.71267 3.1666H0.666666C0.489855 3.1666 0.320286 3.09636 0.195261 2.97133C0.0702372 2.84631 0 2.67674 0 2.49993C0 2.32312 0.0702372 2.15355 0.195261 2.02852C0.320286 1.9035 0.489855 1.83326 0.666666 1.83326H8.71267C8.85576 1.30677 9.16812 0.841999 9.60155 0.510641C10.035 0.179283 10.5654 -0.000244141 11.111 -0.000244141C11.6566 -0.000244141 12.187 0.179283 12.6204 0.510641C13.0539 0.841999 13.3662 1.30677 13.5093 1.83326H15.3333C15.5101 1.83326 15.6797 1.9035 15.8047 2.02852C15.9298 2.15355 16 2.32312 16 2.49993C16 2.67674 15.9298 2.84631 15.8047 2.97133C15.6797 3.09636 15.5101 3.1666 15.3333 3.1666ZM11.1113 1.33326C10.8806 1.33326 10.655 1.40169 10.4632 1.52988C10.2713 1.65808 10.1218 1.84028 10.0335 2.05346C9.94517 2.26664 9.92207 2.50122 9.96708 2.72753C10.0121 2.95385 10.1232 3.16173 10.2864 3.32489C10.4495 3.48805 10.6574 3.59916 10.8837 3.64418C11.11 3.68919 11.3446 3.66609 11.5578 3.57779C11.771 3.48949 11.9532 3.33995 12.0814 3.14809C12.2096 2.95624 12.278 2.73067 12.278 2.49993C12.2776 2.19062 12.1546 1.89408 11.9359 1.67536C11.7172 1.45664 11.4206 1.33361 11.1113 1.33326Z"
      />
      <path
        d="M0.666666 7.33342H2.49067C2.63352 6.80681 2.94575 6.34187 3.37915 6.01038C3.81255 5.67888 4.34302 5.49927 4.88867 5.49927C5.43431 5.49927 5.96478 5.67888 6.39818 6.01038C6.83158 6.34187 7.14381 6.80681 7.28667 7.33342H15.3333C15.5101 7.33342 15.6797 7.40366 15.8047 7.52869C15.9298 7.65371 16 7.82328 16 8.00009C16 8.1769 15.9298 8.34647 15.8047 8.47149C15.6797 8.59652 15.5101 8.66676 15.3333 8.66676H7.28667C7.14381 9.19337 6.83158 9.65831 6.39818 9.98981C5.96478 10.3213 5.43431 10.5009 4.88867 10.5009C4.34302 10.5009 3.81255 10.3213 3.37915 9.98981C2.94575 9.65831 2.63352 9.19337 2.49067 8.66676H0.666666C0.489855 8.66676 0.320286 8.59652 0.195261 8.47149C0.0702372 8.34647 0 8.1769 0 8.00009C0 7.82328 0.0702372 7.65371 0.195261 7.52869C0.320286 7.40366 0.489855 7.33342 0.666666 7.33342ZM4.88867 9.16676C5.11941 9.16676 5.34497 9.09833 5.53683 8.97014C5.72869 8.84194 5.87822 8.65973 5.96653 8.44655C6.05483 8.23337 6.07793 7.9988 6.03292 7.77249C5.9879 7.54617 5.87679 7.33829 5.71362 7.17513C5.55046 7.01197 5.34258 6.90086 5.11627 6.85584C4.88996 6.81082 4.65538 6.83393 4.4422 6.92223C4.22902 7.01053 4.04681 7.16007 3.91862 7.35192C3.79042 7.54378 3.722 7.76935 3.722 8.00009C3.72235 8.3094 3.84538 8.60594 4.0641 8.82466C4.28281 9.04337 4.57936 9.1664 4.88867 9.16676Z"
      />
      <path
        d="M0.666666 12.8333H8.71267C8.85576 12.3068 9.16812 11.842 9.60155 11.5106C10.035 11.1793 10.5654 10.9998 11.111 10.9998C11.6566 10.9998 12.187 11.1793 12.6204 11.5106C13.0539 11.842 13.3662 12.3068 13.5093 12.8333H15.3333C15.5101 12.8333 15.6797 12.9035 15.8047 13.0285C15.9298 13.1535 16 13.3231 16 13.4999C16 13.6767 15.9298 13.8463 15.8047 13.9713C15.6797 14.0964 15.5101 14.1666 15.3333 14.1666H13.5093C13.3662 14.6931 13.0539 15.1579 12.6204 15.4892C12.187 15.8206 11.6566 16.0001 11.111 16.0001C10.5654 16.0001 10.035 15.8206 9.60155 15.4892C9.16812 15.1579 8.85576 14.6931 8.71267 14.1666H0.666666C0.489855 14.1666 0.320286 14.0964 0.195261 13.9713C0.0702372 13.8463 0 13.6767 0 13.4999C0 13.3231 0.0702372 13.1535 0.195261 13.0285C0.320286 12.9035 0.489855 12.8333 0.666666 12.8333ZM11.1113 14.6666C11.3421 14.6666 11.5676 14.5982 11.7595 14.47C11.9514 14.3418 12.1009 14.1596 12.1892 13.9464C12.2775 13.7332 12.3006 13.4986 12.2556 13.2723C12.2106 13.046 12.0995 12.8381 11.9363 12.675C11.7731 12.5118 11.5652 12.4007 11.3389 12.3557C11.1126 12.3107 10.878 12.3338 10.6649 12.4221C10.4517 12.5104 10.2695 12.6599 10.1413 12.8518C10.0131 13.0436 9.94467 13.2692 9.94467 13.4999C9.9452 13.8092 10.0683 14.1056 10.287 14.3243C10.5056 14.543 10.8021 14.6661 11.1113 14.6666Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
