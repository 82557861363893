<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.08646 23.0529C1.08646 22.2495 1.29418 21.5099 1.70963 20.834C2.12509 20.1582 2.67896 19.643 3.37123 19.2884C4.76496 18.5942 6.18356 18.0722 7.62703 17.7224C9.07051 17.3727 10.5362 17.1978 12.0241 17.1978C13.5227 17.1978 14.9937 17.3713 16.4372 17.7184C17.8807 18.0655 19.2939 18.5862 20.677 19.2804C21.3693 19.635 21.9232 20.1489 22.3386 20.8221C22.7541 21.4953 22.9618 22.2389 22.9618 23.0529V24.0398C22.9618 24.8794 22.6662 25.5949 22.0749 26.1862C21.4835 26.7775 20.7681 27.0732 19.9285 27.0732H4.11979C3.28017 27.0732 2.56471 26.7775 1.97343 26.1862C1.38209 25.5949 1.08643 24.8794 1.08643 24.0398L1.08646 23.0529ZM12.0241 15.8645C10.5149 15.8645 9.22618 15.3303 8.15783 14.2619C7.08947 13.1935 6.55529 11.9048 6.55529 10.3956C6.55529 8.88641 7.08947 7.59765 8.15783 6.52929C9.22618 5.46094 10.5149 4.92676 12.0241 4.92676C13.5333 4.92676 14.8221 5.46094 15.8905 6.52929C16.9588 7.59765 17.493 8.88641 17.493 10.3956C17.493 11.9048 16.9588 13.1935 15.8905 14.2619C14.8221 15.3303 13.5333 15.8645 12.0241 15.8645Z"
      fill="#00114E"
    />
    <path
      d="M25.7481 10.3956C25.7481 11.8995 25.2125 13.1869 24.1415 14.2579C23.0705 15.3289 21.7831 15.8645 20.2792 15.8645C20.0295 15.8645 19.7184 15.8353 19.3459 15.7771C18.9734 15.7189 18.6543 15.6512 18.3886 15.5739C18.9993 14.8521 19.467 14.0473 19.7919 13.1594C20.1168 12.2714 20.2792 11.3502 20.2792 10.3956C20.2792 9.44101 20.1168 8.51976 19.7919 7.63182C19.467 6.74391 18.9993 5.93908 18.3886 5.21732C18.705 5.1009 19.0188 5.02337 19.3299 4.98472C19.641 4.94608 19.9575 4.92676 20.2792 4.92676C21.7831 4.92676 23.0705 5.46227 24.1415 6.53329C25.2125 7.60429 25.7481 8.89172 25.7481 10.3956Z"
      fill="#94A2BC"
    />
    <path
      d="M24.7278 27.0732C25.0307 26.6253 25.2565 26.1431 25.4053 25.6264C25.5541 25.1098 25.6285 24.5809 25.6285 24.0398V22.85C25.6285 21.835 25.3563 20.8416 24.8118 19.8699C24.2674 18.8982 23.458 18.0661 22.3836 17.3739C23.4797 17.5125 24.5344 17.7496 25.5477 18.0851C26.561 18.4206 27.4983 18.819 28.3597 19.2804C29.1809 19.7248 29.8119 20.2498 30.2528 20.8554C30.6936 21.4609 30.914 22.1205 30.914 22.834V24.0398C30.914 24.8794 30.6183 25.5949 30.027 26.1862C29.4357 26.7775 28.7203 27.0732 27.8807 27.0732H24.7278Z"
      fill="#94A2BC"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "PhoneIcon",
};
</script>

<style scoped></style>
