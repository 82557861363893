<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.6875C0 2.09867 2.09867 0 4.6875 0H19.3125C21.9013 0 24 2.09867 24 4.6875V19.3125C24 21.9013 21.9013 24 19.3125 24H4.6875C2.09867 24 0 21.9013 0 19.3125V4.6875Z"
      fill="#000000"
    />
    <path
      d="M12.9946 12.2189H15.2279L15.5786 9.93638H12.9942V8.68885C12.9942 7.74064 13.3021 6.89982 14.1837 6.89982H15.6004V4.90789C15.3515 4.87408 14.8251 4.80005 13.8304 4.80005C11.7533 4.80005 10.5356 5.90363 10.5356 8.41787V9.93638H8.40039V12.2189H10.5356V18.4927C10.9585 18.5566 11.3868 18.6 11.8264 18.6C12.2239 18.6 12.6117 18.5635 12.9946 18.5114V12.2189Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
