import { CountryData, LevelsList } from "~/types/statistics";
import { useCountriesStore } from "~/stores/countries";

const changeLevelOrder = (countryData: CountryData): CountryData => {
  const { levels, statistics } = countryData;

  const orderedLevels: LevelsList = {
    ...(levels.vip && { vip: levels.vip }),
    ...(levels.platinum && { platinum: levels.platinum }),
    ...(levels.gold && { gold: levels.gold }),
    ...(levels.silver && { silver: levels.silver }),
    ...(levels.region && { region: levels.region }),
  };

  return {
    levels: orderedLevels,
    statistics,
  };
};

export async function getStatistics(): Promise<CountryData> {
  const res = await import("public/json/statistics.json").then(
    (m) => m.default || m
  );

  const countryCode = useCountriesStore().currentCountry.code;

  return (
    changeLevelOrder(res?.country?.[countryCode]) ?? {
      facility_amount: undefined,
      activity_amount: undefined,
    }
  );
}
