import { default as indexQOtP61wmE0Meta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/index.vue?macro=true";
import { default as indexlWKZLmwr8MMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/[documents]/[document]/index.vue?macro=true";
import { default as indexQNZUijJq1wMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/[documents]/index.vue?macro=true";
import { default as indexJ8ONiL7lWyMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/app/index.vue?macro=true";
import { default as _91id_93TSv83d7a82Meta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/blog/[id].vue?macro=true";
import { default as indexG2KNn6pMeYMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/blog/index.vue?macro=true";
import { default as companiesE7OJggjL6jMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/companies.vue?macro=true";
import { default as contactsE4sIPlHeAUMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/contacts.vue?macro=true";
import { default as cookie_45policy5n4Cj72eLgMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/cookie-policy.vue?macro=true";
import { default as indexThIZHNDYzkMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/facilities-table/index.vue?macro=true";
import { default as indexJ4IkZOYx75Meta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/facilities/index.vue?macro=true";
import { default as holder_45app_45agreementWOmTKPdvbdMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/holder-app-agreement.vue?macro=true";
import { default as holder_45app_45rulesslBUxzzHrHMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/holder-app-rules.vue?macro=true";
import { default as levelsqSi8EaUqsqMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/levels.vue?macro=true";
import { default as new_45app3Pub4dtqeZMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/new-app.vue?macro=true";
import { default as partnersSsKBpNfhRhMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/partners.vue?macro=true";
import { default as processing_45personal_45datazqNsZ9aIUmMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/processing-personal-data.vue?macro=true";
import { default as providing_45payment_45service_45rulesUFuR67GGljMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/providing-payment-service-rules.vue?macro=true";
import { default as public_45offeruBPcChByzPMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/public-offer.vue?macro=true";
import { default as rulesPLOENCTKjQMeta } from "/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/rules.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/index.vue")
  },
  {
    name: "documents-document___ru-by",
    path: "/ru-by/:documents()/:document()",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/[documents]/[document]/index.vue")
  },
  {
    name: "documents___ru-by",
    path: "/ru-by/:documents()",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/[documents]/index.vue")
  },
  {
    name: "app___ru-by",
    path: "/ru-by/app",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/app/index.vue")
  },
  {
    name: "blog-id___ru-by",
    path: "/ru-by/blog/:id()",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/blog/[id].vue")
  },
  {
    name: "blog___ru-by",
    path: "/ru-by/blog",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/blog/index.vue")
  },
  {
    name: "companies___ru-by",
    path: "/ru-by/companies",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/companies.vue")
  },
  {
    name: "contacts___ru-by",
    path: "/ru-by/contacts",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/contacts.vue")
  },
  {
    name: "cookie-policy___ru-by",
    path: "/ru-by/cookie-policy",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/cookie-policy.vue")
  },
  {
    name: "facilities-table___ru-by",
    path: "/ru-by/facilities-table",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/facilities-table/index.vue")
  },
  {
    name: "facilities___ru-by",
    path: "/ru-by/facilities",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/facilities/index.vue")
  },
  {
    name: "holder-app-agreement___ru-by",
    path: "/ru-by/holder-app-agreement",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/holder-app-agreement.vue")
  },
  {
    name: "holder-app-rules___ru-by",
    path: "/ru-by/holder-app-rules",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/holder-app-rules.vue")
  },
  {
    name: "index___ru-by",
    path: "/ru-by",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/index.vue")
  },
  {
    name: "levels___ru-by",
    path: "/ru-by/levels",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/levels.vue")
  },
  {
    name: "new-app___ru-by",
    path: "/ru-by/new-app",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/new-app.vue")
  },
  {
    name: "partners___ru-by",
    path: "/ru-by/partners",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/partners.vue")
  },
  {
    name: "processing-personal-data___ru-by",
    path: "/ru-by/processing-personal-data",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/processing-personal-data.vue")
  },
  {
    name: "providing-payment-service-rules___ru-by",
    path: "/ru-by/providing-payment-service-rules",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/providing-payment-service-rules.vue")
  },
  {
    name: "public-offer___ru-by",
    path: "/ru-by/public-offer",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/public-offer.vue")
  },
  {
    name: "rules___ru-by",
    path: "/ru-by/rules",
    component: () => import("/Users/alexandergolovnya/projects/2303www/app_layers/allsports/pages/rules.vue")
  }
]