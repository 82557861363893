<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g clip-path="url(#clip0_3931_4013)">
      <path
        d="M23.4417 0.558328C23.0841 0.200831 22.5991 0 22.0935 0C21.5878 0 21.1029 0.200831 20.7453 0.558328L12 9.30358L3.25475 0.558328C2.89714 0.200831 2.41219 0 1.90654 0C1.40088 0 0.915933 0.200831 0.558328 0.558328C0.200831 0.915933 0 1.40088 0 1.90654C0 2.41219 0.200831 2.89714 0.558328 3.25475L9.30358 12L0.558328 20.7453C0.200831 21.1029 0 21.5878 0 22.0935C0 22.5991 0.200831 23.0841 0.558328 23.4417C0.915933 23.7992 1.40088 24 1.90654 24C2.41219 24 2.89714 23.7992 3.25475 23.4417L12 14.6964L20.7453 23.4417C21.1029 23.7992 21.5878 24 22.0935 24C22.5991 24 23.0841 23.7992 23.4417 23.4417C23.7992 23.0841 24 22.5991 24 22.0935C24 21.5878 23.7992 21.1029 23.4417 20.7453L14.6964 12L23.4417 3.25475C23.7992 2.89714 24 2.41219 24 1.90654C24 1.40088 23.7992 0.915933 23.4417 0.558328Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
