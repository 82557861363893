<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_697_6005)">
      <path
        d="M6.5854 12.0813C6.77114 12.2672 6.99167 12.4146 7.23441 12.5152C7.47714 12.6158 7.73732 12.6676 8.00007 12.6676C8.26282 12.6676 8.523 12.6158 8.76573 12.5152C9.00847 12.4146 9.229 12.2672 9.41474 12.0813L11.5554 9.94067C11.6702 9.81373 11.7317 9.64752 11.7273 9.47644C11.723 9.30536 11.6529 9.14253 11.5318 9.02165C11.4107 8.90077 11.2477 8.8311 11.0766 8.82707C10.9055 8.82304 10.7394 8.88496 10.6127 9L8.66207 10.9513L8.66674 0.666667C8.66674 0.489856 8.5965 0.320286 8.47147 0.195262C8.34645 0.0702379 8.17688 0 8.00007 0V0C7.82326 0 7.65369 0.0702379 7.52867 0.195262C7.40364 0.320286 7.3334 0.489856 7.3334 0.666667L7.3274 10.9387L5.3874 9C5.26231 8.875 5.09268 8.8048 4.91583 8.80486C4.73899 8.80493 4.56941 8.87524 4.4444 9.00033C4.3194 9.12543 4.24921 9.29506 4.24927 9.4719C4.24933 9.64875 4.31964 9.81833 4.44474 9.94333L6.5854 12.0813Z"
      />
      <path
        d="M15.3333 10.6667C15.1565 10.6667 14.987 10.737 14.8619 10.862C14.7369 10.987 14.6667 11.1566 14.6667 11.3334V14.0001C14.6667 14.1769 14.5964 14.3465 14.4714 14.4715C14.3464 14.5965 14.1768 14.6667 14 14.6667H2C1.82319 14.6667 1.65362 14.5965 1.5286 14.4715C1.40357 14.3465 1.33333 14.1769 1.33333 14.0001V11.3334C1.33333 11.1566 1.2631 10.987 1.13807 10.862C1.01305 10.737 0.843478 10.6667 0.666667 10.6667C0.489856 10.6667 0.320286 10.737 0.195262 10.862C0.0702379 10.987 0 11.1566 0 11.3334L0 14.0001C0 14.5305 0.210714 15.0392 0.585786 15.4143C0.960859 15.7894 1.46957 16.0001 2 16.0001H14C14.5304 16.0001 15.0391 15.7894 15.4142 15.4143C15.7893 15.0392 16 14.5305 16 14.0001V11.3334C16 11.1566 15.9298 10.987 15.8047 10.862C15.6797 10.737 15.5101 10.6667 15.3333 10.6667Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
