<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43" fill="none">
    <path
      d="M5.01699 15.1394L6.27116 16.3936L16.3941 6.27067L15.1399 5.0165C14.7816 4.65817 14.3635 4.479 13.8857 4.479C13.408 4.479 12.9899 4.65817 12.6316 5.0165L10.1232 7.52484L7.52533 10.1228L5.01699 12.6311C4.65866 12.9894 4.47949 13.4075 4.47949 13.8853C4.47949 14.363 4.65866 14.7811 5.01699 15.1394Z"
      fill="#94A2BC"
    />
    <path
      d="M6.22637 8.779L7.52533 10.1228L10.1232 7.52484L8.77949 6.22588C8.42116 5.89741 7.99564 5.74064 7.50293 5.75557C7.01022 5.7705 6.59963 5.9422 6.27116 6.27067C5.91283 6.629 5.73366 7.05452 5.73366 7.54723C5.73366 8.03994 5.8979 8.45053 6.22637 8.779Z"
      fill="#94A2BC"
    />
    <path
      d="M37.9837 27.8603L36.7295 26.6061L26.6066 36.729L27.8607 37.9832C28.2191 38.3415 28.6371 38.5207 29.1149 38.5207C29.5927 38.5207 30.0107 38.3415 30.3691 37.9832L32.8774 35.4748L35.4753 32.8769L37.9837 30.3686C38.342 30.0103 38.5212 29.5922 38.5212 29.1144C38.5212 28.6366 38.342 28.2186 37.9837 27.8603Z"
      fill="#94A2BC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6316 15.1394L7.52533 20.2457C7.19685 20.5741 6.78626 20.7458 6.29355 20.7608C5.80085 20.7757 5.37533 20.604 5.01699 20.2457C4.68852 19.9172 4.51682 19.5066 4.50189 19.0139C4.48696 18.5212 4.64373 18.0957 4.9722 17.7373L6.27116 16.3936L16.3941 6.27067L17.7378 4.97171C18.0663 4.64324 18.4769 4.479 18.9696 4.479C19.4623 4.479 19.8878 4.65817 20.2462 5.0165C20.5746 5.34498 20.7389 5.76303 20.7389 6.27067C20.7389 6.77831 20.5746 7.19637 20.2462 7.52484L15.1399 12.6311L30.3691 27.8603L35.4753 22.754C35.8038 22.4255 36.2144 22.2538 36.7071 22.2389C37.1998 22.224 37.6253 22.3957 37.9837 22.754C38.3121 23.0825 38.4838 23.4931 38.4988 23.9858C38.5137 24.4785 38.3569 24.904 38.0285 25.2623L36.7295 26.6061L26.6066 36.729L25.2628 38.028C24.9344 38.3564 24.5238 38.5207 24.0311 38.5207C23.5383 38.5207 23.1128 38.3415 22.7545 37.9832C22.426 37.6547 22.2618 37.2366 22.2618 36.729C22.2618 36.2214 22.426 35.8033 22.7545 35.4748L27.8607 30.3686L12.6316 15.1394Z"
      fill="#00114E"
    />
    <path
      d="M36.7743 34.2207L35.4753 32.8769L32.8774 35.4748L34.2212 36.7738C34.5795 37.1023 35.005 37.259 35.4977 37.2441C35.9904 37.2292 36.401 37.0575 36.7295 36.729C37.0878 36.3707 37.267 35.9452 37.267 35.4524C37.267 34.9597 37.1028 34.5491 36.7743 34.2207Z"
      fill="#94A2BC"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
