<template>
  <div class="spinner-radial" :style="cssProps">
    <div v-for="i in 8" :key="`spinner-radial-div-${i}`"></div>
  </div>
</template>

<script lang="ts">
import { computed } from "vue";

export const SPINNER_RADIAL_THEMES = {
  dark: Symbol("dark"),
  light: Symbol("light"),
};

const SPINNER_RADIAL_COLORS = {
  [SPINNER_RADIAL_THEMES.dark]: "#346aed",
  [SPINNER_RADIAL_THEMES.light]: "#fff",
};

const DEFAULT_SPINNER_RADIAL_THEME = SPINNER_RADIAL_THEMES.dark;
const DEFAULT_SPINNER_RADIAL_SIZE = "80px";

export default {
  props: {
    size: {
      type: [Number, String],
      default: DEFAULT_SPINNER_RADIAL_SIZE,
    },
    theme: {
      type: Symbol,
      default: DEFAULT_SPINNER_RADIAL_THEME,
      validator: (val: symbol) =>
        Object.values(SPINNER_RADIAL_THEMES).includes(val),
    },
  },

  setup(props) {
    const cssProps = computed(() => {
      const size = props.size.toString().includes("px")
        ? props.size
        : `${props.size}px`;
      const color = SPINNER_RADIAL_COLORS[props.theme];

      return {
        "--spinner-radial-size": size,
        "--spinner-radial-color": color,
      };
    });

    return {
      cssProps,
    };
  },
};
</script>
