import { CookieOptionsKeys, CookieOptionsValues } from "~/types/entities";

export const getCookie = (cookiePolicyKey: string) => {
  return (
    document.cookie.split(";").find((cookie) => {
      return cookie.trim().startsWith(cookiePolicyKey);
    }) || ""
  );
};

export function getParsedCookie(
  cookiePolicyKey: string
): CookieOptionsValues | null {
  const alllsportsAgr = document.cookie.split(";").find((cookie) => {
    return cookie.trim().startsWith(cookiePolicyKey);
  });

  const trimmedAlllsportsAgr = alllsportsAgr?.trim();
  if (!trimmedAlllsportsAgr) {
    return null;
  }
  const regex = new RegExp(`${cookiePolicyKey}=`, "g");
  const alllsportsAgrVal = decodeURIComponent(
    trimmedAlllsportsAgr.replace(regex, "")
  );

  const parsedAlllsportsAgrVal = JSON.parse(
    alllsportsAgrVal
  ) as CookieOptionsValues;

  const requiredKeys = Object.values(CookieOptionsKeys);

  const allKeysPresent = Object.keys(parsedAlllsportsAgrVal).every(
    (key) => requiredKeys.includes(key as CookieOptionsKeys)
  );

  if (allKeysPresent) {
    return parsedAlllsportsAgrVal;
  }

  return null;
}
