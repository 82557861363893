import { brandData } from "@brand/model";

export const useCountriesStore = defineStore("possibleCountries", () => {
  const { t } = useI18n();

  const countryCode = ref<typeof brandData.availableCountryCodes[number]>(brandData.defaultCountryCode);
  const currentCountry = computed(() => (
    { code: countryCode.value, value: t(countryCode.value) }
  ));

  function setCountry(newCountryCode: typeof brandData.availableCountryCodes[number]) {
    countryCode.value = newCountryCode;
  }

  return { setCountry, currentCountry };
});