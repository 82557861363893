<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3043 7.30437H0.695641C0.311445 7.30437 0 7.61581 0 8.00001C0 8.3842 0.311445 8.69565 0.695641 8.69565H15.3043C15.6885 8.69565 16 8.3842 16 8.00001C16 7.61581 15.6885 7.30437 15.3043 7.30437Z"
    />
    <path
      d="M0.695641 3.8261H15.3043C15.6885 3.8261 16 3.51465 16 3.13046C16 2.74626 15.6885 2.43481 15.3043 2.43481H0.695641C0.311445 2.43481 0 2.74626 0 3.13046C0 3.51465 0.311445 3.8261 0.695641 3.8261Z"
    />
    <path
      d="M15.3043 12.1739H0.695641C0.311445 12.1739 0 12.4854 0 12.8696C0 13.2538 0.311445 13.5652 0.695641 13.5652H15.3043C15.6885 13.5652 16 13.2538 16 12.8696C16 12.4854 15.6885 12.1739 15.3043 12.1739Z"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
