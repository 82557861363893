<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="12"
    viewBox="0 0 36 12"
    fill="none"
  >
    <path
      d="M2 2V9C2 9.55228 2.44772 10 3 10H34"
      stroke="#94A0BC"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>
