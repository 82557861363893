<template>
  <svg
    id="Gradient"
    height="512"
    viewBox="0 0 24 24"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="12"
      x2="12"
      y1="2.42"
      y2="21.58"
    >
      <stop offset="0" stop-color="#ffe61c" />
      <stop offset="1" stop-color="#ffa929" />
    </linearGradient>
    <path
      d="m12.0006 18.9543-4.6878 2.4639c-1.0101.5313-2.1901-.3263-1.9976-1.4513l.8951-5.2204-3.7927-3.6977c-.8177-.7963-.3664-2.1839.7624-2.3489l5.2416-.7613 2.3439-4.7503c.505-1.0238 1.9639-1.0238 2.4689 0l2.3439 4.7503 5.2417.7613c1.1288.1638 1.5801 1.5513.7626 2.3489l-3.7928 3.6977.8951 5.2204c.1925 1.1251-.9876 1.9826-1.9976 1.4513z"
      fill="url(#SVGID_1_)"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
