import { CooperationTypes, Level, User } from "~/types/entities";

export const SuggestionEvents = {
  getOfferModal: "suggestion_none_button_get-offer-modal",
};

export const AdvantagesEvents = {
  getOfferModal: "advantages_none_button_get-offer-modal",
  askQuestionModal: "advantages_none_button_get-offer-modal",
  [User.members]: "advantages_tab-group_tab-members",
  [User.partners]: "advantages_tab-group_tab-partners",
  [User.companies]: "advantages_tab-group_tab-companies",
};

export const LevelsEvents = {
  toFacilitiesPage: "levels_none_nav-link_facilities-page-link",
  [Level.REGION]: "levels_tab-group_tab-region",
  [Level.SILVER]: "levels_tab-group_tab-silver",
  [Level.GOLD]: "levels_tab-group_tab-gold",
  [Level.PLATINUM]: "levels_tab-group_tab-platinum",
};

export const GetOfferModalEvents = {
  close: "get-offer-modal_none_button_close",
  submit: "get-offer-modal_none_button_submit",
  support: "get-offer-modal_none_button_support",
  [CooperationTypes.PARTNER]: "get-offer-modal_tab-group_tab-partners",
  [CooperationTypes.CONNECT_COMPANY]: "get-offer-modal_tab-group_tab-companies",
};

export const ScrollSliderEvents = {
  next: "scroll-slider_slider-controls_control-next",
  prev: "scroll-slider_slider-controls_control-prev",
  start: "scroll-slider_slider-controls_control-start",
};

export const FeedbackEvents = {
  feedbackModal: "feedback_none_button_feedback-modal",
};

export const FAQEvents = {
  toCompaniesPage: "faq_none_nav-link_companies-page-link",
  toPartnersPage: "faq_none_nav-link_partners-page-link",
  askQuestionModal: "faq_none_button_ask-question-modal",
  showAnswer: "faq_question-list_question-dropdown",
  [User.members]: "faq_tab-group_tab-members",
  [User.partners]: "faq_tab-group_tab-partners",
  [User.companies]: "faq_tab-group_tab-companies",
};

export const GetDetailsEvents = {
  submit: "get-details_none_button_submit",
  [CooperationTypes.PARTNER]: "get-details_tab-group_tab-partners",
  [CooperationTypes.CONNECT_COMPANY]: "get-details_tab-group_tab-companies",
};

export const HeaderEvents = {
  getOfferModal: "header_none_button_get-offer-modal",
};

export const MapEvents = {
  cluster: "map_clusters_div_facilities-markers",
  facilityObjectModal: "map_markers_div_facility-object-modal",
};

export const FacilitiesEvents = {
  cityChange: "facilities_none_none_city-change",
};

const asAsterisk = (string: string) => string.replace(/./g, "*");
const asAsteriskExceptLast4 = (string: string) => string.length > 4
  ? string.substring(string.length - 4).replace(/./g, "*") + string.substring(string.length - 4)
  : asAsterisk(string);

export function hidePD(object: Object) {
  const result = {...object}
  Object.keys(object).map((key) => {
    if (['email', 'phone', 'name', 'companyName'].indexOf(key) !== -1) {
      // @ts-ignore
      result[key] = asAsteriskExceptLast4(object[key]);
    }
  })
  return result
}