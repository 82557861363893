<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M14.3329 25.6667V18.9129L8.46622 22.3026C8.06451 22.5283 7.64228 22.5804 7.19955 22.459C6.75682 22.3377 6.42263 22.0709 6.19698 21.6587C5.97134 21.2624 5.91482 20.842 6.02742 20.3975C6.14002 19.9531 6.3974 19.6197 6.79955 19.3975L12.6662 16.0001L6.79955 12.636C6.3974 12.4159 6.14002 12.0818 6.02742 11.6337C5.91482 11.1856 5.97134 10.7608 6.19698 10.359C6.42263 9.95734 6.7611 9.6958 7.21238 9.57445C7.66365 9.45307 8.09014 9.5052 8.49185 9.73085L14.3329 13.1206V6.33341C14.3329 5.8731 14.4952 5.48025 14.8198 5.15485C15.1444 4.82945 15.5362 4.66675 15.9954 4.66675C16.4546 4.66675 16.8479 4.82945 17.1752 5.15485C17.5025 5.48025 17.6662 5.8731 17.6662 6.33341V13.1206L23.5329 9.73085C23.9346 9.5052 24.3568 9.45307 24.7996 9.57445C25.2423 9.6958 25.5765 9.95734 25.8021 10.359C26.0278 10.7608 26.0813 11.1856 25.9627 11.6337C25.8441 12.0818 25.5872 12.4159 25.1919 12.636L19.3329 16.0001L25.1996 19.3975C25.6017 19.6197 25.8591 19.9531 25.9717 20.3975C26.0843 20.842 26.0278 21.2703 25.8021 21.6825C25.5765 22.0788 25.238 22.3377 24.7867 22.459C24.3355 22.5804 23.909 22.5283 23.5073 22.3026L17.6662 18.9129V25.6667C17.6662 26.1271 17.5039 26.5199 17.1793 26.8453C16.8548 27.1707 16.4629 27.3334 16.0037 27.3334C15.5445 27.3334 15.1513 27.1707 14.8239 26.8453C14.4966 26.5199 14.3329 26.1271 14.3329 25.6667Z"
      fill="#00114E"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "PhoneIcon",
};
</script>

<style scoped></style>
