<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2035_1247)">
      <path
        d="M15.045 -0.000226562C15.018 -0.00122656 8.982 -0.00122656 8.955 -0.000226562C6.219 0.0237734 4 2.25777 4 4.99877V18.9988C4 21.7558 6.243 23.9988 9 23.9988H15C17.757 23.9988 20 21.7558 20 18.9988V4.99977C20 2.25777 17.781 0.0247734 15.045 -0.000226562ZM18 18.9988C18 20.6528 16.654 21.9988 15 21.9988H9C7.346 21.9988 6 20.6528 6 18.9988V4.99977C6 3.54677 7.038 2.33277 8.411 2.05777L9.105 3.44677C9.274 3.78577 9.621 3.99977 10 3.99977H14C14.379 3.99977 14.725 3.78577 14.895 3.44677L15.589 2.05777C16.962 2.33177 18 3.54677 18 4.99977V18.9988ZM13 19.9988H11C10.448 19.9988 10 19.5508 10 18.9988C10 18.4468 10.448 17.9988 11 17.9988H13C13.552 17.9988 14 18.4468 14 18.9988C14 19.5508 13.552 19.9988 13 19.9988Z"
        fill="#00114E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2035_1247">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
