<template>
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.913 13.3921H0.0257568L1.06962 15.1249C2.67423 14.2378 4.72745 14.7601 5.65916 16.3105C6.58224 17.8527 6.03874 19.826 4.4255 20.7214L5.46936 22.4625L10.913 13.3921Z"
      fill="#346AED"
    />
    <path
      d="M10.8872 12.1735L5.44361 3.11133L4.39974 4.84417C6.00436 5.7396 6.55648 7.71288 5.6334 9.25503C4.70169 10.7972 2.64847 11.3278 1.04386 10.4407L0 12.1735H10.8872Z"
      fill="#346AED"
    />
    <path
      d="M11.9742 11.5432L17.4178 2.48108H15.3301C15.3301 4.26366 13.829 5.70631 11.9742 5.70631C10.1194 5.70631 8.6183 4.26366 8.6183 2.48108H6.53058L11.9742 11.5432Z"
      fill="#346AED"
    />
    <path
      d="M13.0873 12.1321H23.9745L22.9306 10.3993C21.326 11.2864 19.2728 10.7641 18.3411 9.21363C17.418 7.67149 17.9615 5.69821 19.5747 4.80277L18.5309 3.06165L13.0873 12.1321Z"
      fill="#346AED"
    />
    <path
      d="M13.1128 13.3423L18.5564 22.4044L19.6002 20.6633C17.9956 19.7679 17.4435 17.7946 18.3666 16.2524C19.2983 14.7103 21.3515 14.1797 22.9561 15.0668L24 13.334H13.1128V13.3423Z"
      fill="#346AED"
    />
    <path
      d="M12.0258 13.9801L6.58221 23.0423H8.66993C8.66993 21.2597 10.171 19.817 12.0258 19.817C13.8806 19.817 15.3817 21.2597 15.3817 23.0423H17.4694L12.0258 13.9801Z"
      fill="#00114E"
    />
    <path
      d="M13.7082 2.48081C13.7082 3.3764 12.9577 4.09785 12.026 4.09785C11.1029 4.09785 10.3437 3.3764 10.3437 2.48081C10.3437 1.59351 11.0943 0.86377 12.026 0.86377C12.9577 0.86377 13.7082 1.58522 13.7082 2.48081Z"
      fill="#00114E"
    />
    <path
      d="M13.7082 23.0425C13.7082 23.9381 12.9577 24.6595 12.026 24.6595C11.1029 24.6595 10.3437 23.9381 10.3437 23.0425C10.3437 22.1552 11.0943 21.4254 12.026 21.4254C12.9577 21.4254 13.7082 22.1469 13.7082 23.0425Z"
      fill="#346AED"
    />
    <path
      d="M4.66709 17.6451C4.66709 18.5407 3.91655 19.2622 2.98484 19.2622C2.06175 19.2622 1.30258 18.5407 1.30258 17.6451C1.30258 16.7578 2.05313 16.0281 2.98484 16.0281C3.90792 16.0281 4.66709 16.7495 4.66709 17.6451Z"
      fill="#00114E"
    />
    <path
      d="M22.9563 17.6451C22.9563 18.5407 22.2058 19.2622 21.2741 19.2622C20.351 19.2622 19.5918 18.5407 19.5918 17.6451C19.5918 16.7578 20.3424 16.0281 21.2741 16.0281C22.1972 16.0281 22.9563 16.7495 22.9563 17.6451Z"
      fill="#00114E"
    />
    <path
      d="M22.9563 7.63816C22.9563 8.53375 22.2058 9.25521 21.2741 9.25521C20.351 9.25521 19.5918 8.53375 19.5918 7.63816C19.5918 6.75086 20.3424 6.02112 21.2741 6.02112C22.1972 6.02941 22.9563 6.75086 22.9563 7.63816Z"
      fill="#00114E"
    />
    <path
      d="M4.66709 7.63816C4.66709 8.53375 3.91655 9.25521 2.98484 9.25521C2.06175 9.25521 1.30258 8.53375 1.30258 7.63816C1.30258 6.75086 2.05313 6.02112 2.98484 6.02112C3.90792 6.02941 4.66709 6.75086 4.66709 7.63816Z"
      fill="#00114E"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
