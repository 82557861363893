import { CooperationTypes } from "~/types/entities";

export const GET_AN_OFFER_MODAL_ID = "get-an-offer"

export function useGetAnOfferFormModal() {
  const router = useRouter();
  const route = useRoute();

  const offerFormModalState = computed(() => {
    type ModalState = { isModalOpen: boolean; cooperationType: CooperationTypes | undefined };
    const result: ModalState = { isModalOpen: false, cooperationType: undefined };

    const { modal, "cooperation-type": cooperationType } = route.query;

    if (modal) {
      result.isModalOpen = modal === GET_AN_OFFER_MODAL_ID;

      result.cooperationType =
        (cooperationType && (cooperationType === CooperationTypes.PARTNER || cooperationType === CooperationTypes.CONNECT_COMPANY))
          ? cooperationType
          : undefined;
    }

    return result;
  });

  async function showModal(showModal: boolean, cooperationType?: CooperationTypes) {
    if (showModal) {
      await router.push({ query: { modal: GET_AN_OFFER_MODAL_ID, "cooperation-type": cooperationType }});
    } else {
      await router.push({ path: route.path, query: {}});
    }
  }

  return {
    offerFormModalState,
    showModal
  };
}