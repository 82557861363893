import { isObject, isString, isStringArray } from "./utils";

type CountryCodeWithBounds = { [countryCode: string]: {portrait: readonly (readonly number[])[], landscape: readonly (readonly number[])[]} };

type BrandData<AC, DC, CCWB> = {
  availableCountryCodes: AC;
  defaultCountryCode: DC;
  countryCodeWithBounds: CCWB;
}

// "AC" stands for available countries, "DC" stands for default country,
// and so on for brevity
export class BrandDataBuilder<AC, DC, CCWB> {
  private readonly data: BrandData<AC, DC, CCWB>;

  constructor() {
    this.data = {
      availableCountryCodes: null as unknown as AC,
      defaultCountryCode: null as unknown as DC,
      countryCodeWithBounds: null as unknown as CCWB,
    };
  }

  static create() {
    return new BrandDataBuilder<never, never, never>();
  }

  setAvailableCountryCodes<const NewCountries extends readonly string[]>(availableCountryCodes: NewCountries): BrandDataBuilder<NewCountries, DC, CCWB> {
    this.data.availableCountryCodes = availableCountryCodes as unknown as AC;
    return this as unknown as BrandDataBuilder<NewCountries, DC, CCWB>;
  }

  setDefaultCountryCode<const NewDefault extends string>(defaultCountryCode: NewDefault): BrandDataBuilder<AC, NewDefault, CCWB> {
    this.data.defaultCountryCode = defaultCountryCode as unknown as DC;
    return this as unknown as BrandDataBuilder<AC, NewDefault, CCWB>;
  }
  
  setCountryCodeWithBounds<const NewCountryWithBounds extends Readonly<CountryCodeWithBounds>>(countryCodeWithBounds: NewCountryWithBounds): BrandDataBuilder<AC, DC, NewCountryWithBounds> {
    this.data.countryCodeWithBounds = countryCodeWithBounds as unknown as CCWB;
    return this as unknown as BrandDataBuilder<AC, DC, NewCountryWithBounds>;
  }

  build(): BrandData<AC, DC, CCWB> {
    const { availableCountryCodes, defaultCountryCode, countryCodeWithBounds } = this.data;

    if (
      !(isStringArray(availableCountryCodes) && availableCountryCodes.length) ||
      !(isString(defaultCountryCode) && defaultCountryCode.length)
    )
      throw new Error("Set the default and available country codes");

    if (!availableCountryCodes.includes(defaultCountryCode))
      throw new Error("The defaultCountryCode must be in the availableCountryCodes");

    if (
      !(isObject(countryCodeWithBounds) && Object.values(countryCodeWithBounds).length)
    )
      throw new Error("Set the countryCodeWithBounds");

    const countries = Object.keys(countryCodeWithBounds);
    const allCountriesMatch = availableCountryCodes.every(availableCountryCode => countries.includes(availableCountryCode));

    if (!allCountriesMatch)
      throw new Error("The availableCountryCodes array and countryCodeWithBounds must match");

    return this.data;
  }
}
