<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.6875C0 2.09867 2.09867 0 4.6875 0H19.3125C21.9013 0 24 2.09867 24 4.6875V19.3125C24 21.9013 21.9013 24 19.3125 24H4.6875C2.09867 24 0 21.9013 0 19.3125V4.6875Z"
      fill="#00114E"
    />
    <path
      d="M12.5899 16.2C8.07997 16.2 5.50757 13.0469 5.40039 7.80005H7.65948C7.73368 11.6511 9.39914 13.2823 10.7183 13.6187V7.80005H12.8455V11.1214C14.1482 10.9784 15.5168 9.46491 15.9785 7.80005H18.1057C17.9317 8.6635 17.5849 9.48104 17.087 10.2015C16.589 10.9221 15.9506 11.53 15.2118 11.9874C16.0365 12.4054 16.765 12.9969 17.3491 13.7231C17.9332 14.4492 18.3597 15.2935 18.6004 16.2H16.2589C16.0428 15.4126 15.6036 14.7077 14.9964 14.1737C14.3892 13.6398 13.641 13.3004 12.8455 13.1982V16.2H12.5899Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
