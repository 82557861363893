<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.8344 14.4326C7.90715 15.475 9.20106 15.9961 10.7161 15.9961C12.2312 15.9961 13.5238 15.475 14.5939 14.4326C15.664 13.3902 16.1991 12.133 16.1991 10.6609C16.1991 9.18876 15.664 7.93279 14.5939 6.89298C13.5238 5.85316 12.2312 5.33325 10.7161 5.33325C9.20106 5.33325 7.90715 5.85316 6.8344 6.89298C5.76164 7.93279 5.22527 9.18876 5.22527 10.6609C5.22527 12.133 5.76164 13.3902 6.8344 14.4326ZM0 22.8393V23.7789C0 24.5782 0.289634 25.2593 0.868967 25.8222C1.4483 26.3851 2.14927 26.6666 2.97189 26.6666H18.4525C19.2751 26.6666 19.9761 26.3851 20.5554 25.8222C21.1348 25.2593 21.4244 24.5782 21.4244 23.7789V22.8393C21.4244 22.0644 21.2222 21.3565 20.8178 20.7157C20.4133 20.0748 19.872 19.5856 19.1937 19.248C17.8387 18.5872 16.454 18.0915 15.0398 17.7611C13.6255 17.4306 12.1843 17.2654 10.7161 17.2654C9.26354 17.2654 7.82883 17.4319 6.41197 17.7649C4.99513 18.0978 3.60136 18.5947 2.23068 19.2556C1.55244 19.5932 1.01108 20.0832 0.606625 20.7258C0.202208 21.3683 0 22.0728 0 22.8393Z"
      fill="#00114E"
    />
    <path
      d="M16.7351 15.9175C17.4134 15.2 17.9291 14.3861 18.2822 13.4758C18.6352 12.5654 18.8118 11.6259 18.8118 10.6571C18.8118 9.68833 18.63 8.74497 18.2665 7.82703C17.903 6.90906 17.3874 6.10149 16.7195 5.4043C18.1091 5.61401 19.2407 6.22142 20.1142 7.22652C20.9877 8.23162 21.4244 9.37641 21.4244 10.6609C21.4244 11.9656 20.9851 13.118 20.1064 14.118C19.2277 15.1181 18.1039 15.7179 16.7351 15.9175Z"
      fill="#94A2BC"
    />
    <path
      d="M23.1546 26.6666C23.4514 26.2403 23.6726 25.7812 23.8184 25.2893C23.9642 24.7975 24.0371 24.294 24.0371 23.7789V22.631C24.0371 21.7986 23.8655 21.026 23.5224 20.3131C23.1792 19.6003 22.709 18.9643 22.1117 18.405C23.321 18.7605 24.4005 19.2701 25.3502 19.9337C26.2999 20.5974 26.7747 21.4964 26.7747 22.631V23.7789C26.7747 24.5782 26.485 25.2593 25.9057 25.8222C25.3264 26.3851 24.6254 26.6666 23.8028 26.6666H23.1546Z"
      fill="#94A2BC"
    />
    <path
      d="M26.7513 14.6433H25.4215C25.0514 14.6433 24.7411 14.5217 24.4908 14.2784C24.2404 14.0351 24.1152 13.7337 24.1152 13.374C24.1152 13.0144 24.2404 12.7129 24.4908 12.4696C24.7411 12.2264 25.0514 12.1047 25.4215 12.1047H26.7513V10.8127C26.7513 10.453 26.8764 10.1516 27.1268 9.90828C27.3772 9.66499 27.6875 9.54335 28.0576 9.54335C28.4277 9.54335 28.738 9.66499 28.9883 9.90828C29.2387 10.1516 29.3639 10.453 29.3639 10.8127V12.1047H30.6937C31.0638 12.1047 31.3741 12.2264 31.6244 12.4696C31.8748 12.7129 32 13.0144 32 13.374C32 13.7337 31.8748 14.0351 31.6244 14.2784C31.3741 14.5217 31.0638 14.6433 30.6937 14.6433H29.3639V15.9354C29.3639 16.2951 29.2387 16.5965 28.9883 16.8398C28.738 17.0831 28.4277 17.2047 28.0576 17.2047C27.6875 17.2047 27.3772 17.0831 27.1268 16.8398C26.8764 16.5965 26.7513 16.2951 26.7513 15.9354V14.6433Z"
      fill="#94A2BC"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "PhoneIcon",
};
</script>

<style scoped></style>
