<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g id="phone_in_talk_FILL1_wght400_GRAD0_opsz24 1">
      <path
        id="Vector"
        d="M20.5 12C20.2333 12 19.9958 11.9042 19.7875 11.7125C19.5792 11.5208 19.4583 11.2833 19.425 11C19.2083 9.45 18.5542 8.12917 17.4625 7.0375C16.3708 5.94583 15.05 5.29167 13.5 5.075C13.2167 5.04167 12.9792 4.925 12.7875 4.725C12.5958 4.525 12.5 4.28333 12.5 4C12.5 3.71667 12.6 3.47917 12.8 3.2875C13 3.09583 13.2333 3.01667 13.5 3.05C15.6 3.28333 17.3917 4.14167 18.875 5.625C20.3583 7.10833 21.2167 8.9 21.45 11C21.4833 11.2667 21.4042 11.5 21.2125 11.7C21.0208 11.9 20.7833 12 20.5 12ZM16.325 12C16.1083 12 15.9167 11.925 15.75 11.775C15.5833 11.625 15.4583 11.425 15.375 11.175C15.2417 10.6917 14.9875 10.2625 14.6125 9.8875C14.2375 9.5125 13.8083 9.25833 13.325 9.125C13.075 9.04167 12.875 8.91667 12.725 8.75C12.575 8.58333 12.5 8.38333 12.5 8.15C12.5 7.81667 12.6167 7.54583 12.85 7.3375C13.0833 7.12917 13.3417 7.05833 13.625 7.125C14.5583 7.34167 15.3625 7.7875 16.0375 8.4625C16.7125 9.1375 17.1583 9.94167 17.375 10.875C17.4417 11.1583 17.3667 11.4167 17.15 11.65C16.9333 11.8833 16.6583 12 16.325 12ZM20.45 21C18.3667 21 16.3083 20.5458 14.275 19.6375C12.2417 18.7292 10.3917 17.4417 8.725 15.775C7.05833 14.1083 5.77083 12.2583 4.8625 10.225C3.95417 8.19167 3.5 6.13333 3.5 4.05C3.5 3.75 3.6 3.5 3.8 3.3C4 3.1 4.25 3 4.55 3H8.6C8.83333 3 9.04167 3.07917 9.225 3.2375C9.40833 3.39583 9.51667 3.58333 9.55 3.8L10.2 7.3C10.2333 7.56667 10.225 7.79167 10.175 7.975C10.125 8.15833 10.0333 8.31667 9.9 8.45L7.475 10.9C7.80833 11.5167 8.20417 12.1125 8.6625 12.6875C9.12083 13.2625 9.625 13.8167 10.175 14.35C10.6917 14.8667 11.2333 15.3458 11.8 15.7875C12.3667 16.2292 12.9667 16.6333 13.6 17L15.95 14.65C16.1 14.5 16.2958 14.3875 16.5375 14.3125C16.7792 14.2375 17.0167 14.2167 17.25 14.25L20.7 14.95C20.9333 15.0167 21.125 15.1375 21.275 15.3125C21.425 15.4875 21.5 15.6833 21.5 15.9V19.95C21.5 20.25 21.4 20.5 21.2 20.7C21 20.9 20.75 21 20.45 21Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {
  name: "PhoneIcon",
};
</script>

<style scoped></style>
