<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
  >
    <path
      d="M14.9607 22.5301C14.3635 21.9031 13.7886 21.1715 13.2362 20.3354C12.6838 19.4992 12.1985 18.469 11.7805 17.2447L15.2294 16.3937C15.528 17.1999 15.8714 17.8867 16.2596 18.4541C16.6478 19.0215 17.0659 19.5291 17.5138 19.977L14.9607 22.5301Z"
      fill="#94A2BC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7086 37.6249V30.4583C19.7086 28.786 19.4548 27.5468 18.9471 26.7406C18.4395 25.9343 17.7676 25.143 16.9315 24.3666L19.4846 21.8135C19.843 22.1419 20.1864 22.4928 20.5148 22.8661C20.8433 23.2393 21.1718 23.635 21.5003 24.0531C21.9183 23.4857 22.3438 22.9855 22.7768 22.5525C23.2098 22.1196 23.6503 21.694 24.0982 21.276C25.2329 20.2308 26.2631 19.0215 27.1888 17.6479C28.1145 16.2742 28.6072 13.8704 28.6669 10.4364L27.0992 12.0041C26.7707 12.3326 26.3602 12.4968 25.8674 12.4968C25.3747 12.4968 24.9492 12.3326 24.5909 12.0041C24.2326 11.6458 24.0534 11.2202 24.0534 10.7275C24.0534 10.2348 24.2326 9.80931 24.5909 9.45098L29.2044 4.83743C29.3836 4.65827 29.5777 4.53136 29.7867 4.45671C29.9957 4.38205 30.2197 4.34473 30.4586 4.34473C30.6975 4.34473 30.9214 4.38205 31.1305 4.45671C31.3395 4.53136 31.5336 4.65827 31.7128 4.83743L36.3711 9.49577C36.6996 9.82424 36.8713 10.2348 36.8862 10.7275C36.9011 11.2202 36.7294 11.6458 36.3711 12.0041C36.0426 12.3326 35.6246 12.4968 35.1169 12.4968C34.6093 12.4968 34.1912 12.3326 33.8628 12.0041L32.2503 10.4364C32.1905 14.7065 31.5336 17.7449 30.2794 19.5515C29.0253 21.3581 27.7711 22.8288 26.5169 23.9635C25.5614 24.8294 24.785 25.673 24.1878 26.4942C23.5905 27.3154 23.2919 28.6367 23.2919 30.4583V37.6249C23.2919 38.1326 23.1202 38.5581 22.7768 38.9015C22.4334 39.2449 22.0079 39.4166 21.5003 39.4166C20.9926 39.4166 20.5671 39.2449 20.2237 38.9015C19.8803 38.5581 19.7086 38.1326 19.7086 37.6249Z"
      fill="#00114E"
    />
    <path
      d="M11.1086 14.6468C10.9891 14.0496 10.907 13.3926 10.8622 12.676C10.8174 11.9593 10.7801 11.2128 10.7503 10.4364L9.13776 12.0489C8.80929 12.3774 8.3987 12.5416 7.90599 12.5416C7.41328 12.5416 6.98776 12.3624 6.62943 12.0041C6.30095 11.6756 6.13672 11.2576 6.13672 10.7499C6.13672 10.2423 6.30095 9.82424 6.62943 9.49577L11.2878 4.83743C11.4669 4.65827 11.661 4.53136 11.8701 4.45671C12.0791 4.38205 12.303 4.34473 12.5419 4.34473C12.7808 4.34473 13.0048 4.38205 13.2138 4.45671C13.4228 4.53136 13.6169 4.65827 13.7961 4.83743L18.4544 9.49577C18.8128 9.8541 18.9845 10.2722 18.9695 10.7499C18.9546 11.2277 18.768 11.6458 18.4096 12.0041C18.0513 12.3326 17.6332 12.4968 17.1555 12.4968C16.6777 12.4968 16.2596 12.3326 15.9013 12.0041L14.3336 10.4812C14.3336 11.1083 14.3635 11.698 14.4232 12.2505C14.4829 12.8029 14.5426 13.318 14.6023 13.7958L11.1086 14.6468Z"
      fill="#94A2BC"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
