<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
  >
    <path
      d="M6.6913 40.042C6.21763 39.5759 5.98737 39.0172 6.00053 38.3659C6.0137 37.7146 6.23981 37.165 6.67888 36.717L17.5307 25.7296C18.4434 24.8155 19.5633 24.3584 20.8902 24.3584C22.217 24.3584 23.3368 24.8155 24.2495 25.7296L29.2039 30.7312L39.3904 20.5959C39.8295 20.1562 40.3807 19.9363 41.0441 19.9363C41.7076 19.9363 42.2588 20.1561 42.6978 20.5958C43.1715 21.062 43.4018 21.6206 43.3886 22.2719C43.3754 22.9232 43.1493 23.4726 42.7103 23.9206L32.5633 34.0833C31.6505 34.9974 30.5308 35.4545 29.2039 35.4545C27.877 35.4545 26.7572 34.9974 25.8445 34.0833L20.8902 29.1338L9.99871 40.042C9.55967 40.4817 9.00844 40.7015 8.345 40.7015C7.68157 40.7015 7.13033 40.4817 6.6913 40.042Z"
      fill="#00114E"
    />
    <path
      d="M38.9893 18.2357H41.8923H45.2121V21.5482V24.4557C45.2121 25.1202 45.4403 25.681 45.8966 26.138C46.353 26.5951 46.9129 26.8236 47.5763 26.8236C48.2398 26.8236 48.7997 26.5951 49.256 26.138C49.7124 25.681 49.9406 25.1202 49.9406 24.4557V15.8679C49.9406 15.2034 49.7124 14.6426 49.256 14.1856C48.7997 13.7285 48.2398 13.5 47.5763 13.5H38.9893C38.3258 13.5 37.7659 13.7285 37.3095 14.1856C36.8532 14.6426 36.625 15.2034 36.625 15.8679C36.625 16.5323 36.8532 17.0931 37.3095 17.5501C37.7659 18.0072 38.3258 18.2357 38.9893 18.2357Z"
      fill="#94A2BC"
    />
  </svg>
</template>
