<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M15.9993 22.667C16.3771 22.667 16.6938 22.5392 16.9493 22.2837C17.2049 22.0281 17.3327 21.7114 17.3327 21.3337V16.0003C17.3327 15.6225 17.2049 15.3059 16.9493 15.0503C16.6938 14.7948 16.3771 14.667 15.9993 14.667C15.6216 14.667 15.3049 14.7948 15.0493 15.0503C14.7938 15.3059 14.666 15.6225 14.666 16.0003V21.3337C14.666 21.7114 14.7938 22.0281 15.0493 22.2837C15.3049 22.5392 15.6216 22.667 15.9993 22.667ZM15.9993 12.0003C16.3771 12.0003 16.6938 11.8725 16.9493 11.617C17.2049 11.3614 17.3327 11.0448 17.3327 10.667C17.3327 10.2892 17.2049 9.97255 16.9493 9.71699C16.6938 9.46144 16.3771 9.33366 15.9993 9.33366C15.6216 9.33366 15.3049 9.46144 15.0493 9.71699C14.7938 9.97255 14.666 10.2892 14.666 10.667C14.666 11.0448 14.7938 11.3614 15.0493 11.617C15.3049 11.8725 15.6216 12.0003 15.9993 12.0003ZM15.9993 29.3337C14.1549 29.3337 12.4216 28.9837 10.7993 28.2837C9.17713 27.5837 7.76602 26.6337 6.56602 25.4337C5.36602 24.2337 4.41602 22.8225 3.71602 21.2003C3.01602 19.5781 2.66602 17.8448 2.66602 16.0003C2.66602 14.1559 3.01602 12.4225 3.71602 10.8003C4.41602 9.1781 5.36602 7.76699 6.56602 6.56699C7.76602 5.36699 9.17713 4.41699 10.7993 3.71699C12.4216 3.01699 14.1549 2.66699 15.9993 2.66699C17.8438 2.66699 19.5771 3.01699 21.1993 3.71699C22.8216 4.41699 24.2327 5.36699 25.4327 6.56699C26.6327 7.76699 27.5827 9.1781 28.2827 10.8003C28.9827 12.4225 29.3327 14.1559 29.3327 16.0003C29.3327 17.8448 28.9827 19.5781 28.2827 21.2003C27.5827 22.8225 26.6327 24.2337 25.4327 25.4337C24.2327 26.6337 22.8216 27.5837 21.1993 28.2837C19.5771 28.9837 17.8438 29.3337 15.9993 29.3337Z"
    />
  </svg>
</template>
