import { BrandDataBuilder } from "~/app_layers/base/model/brandData";

// Set the correct data when the following countries are used in the client.
// Currently, they are needed only for the mobile app.
const mockData = {
  countyCodes: ["am", "ru"],
  bounds: {
    am: {
      portrait: [ [0, 0], [0, 0] ],
      landscape: [ [0, 0], [0, 0] ],
    },
    ru: {
      portrait: [ [0, 0], [0, 0] ],
      landscape: [ [0, 0], [0, 0] ],
    },
  }
} as const;

const availableCountries = [ ...mockData.countyCodes, "by" ] as const;

export const brandData = BrandDataBuilder.create()
  .setAvailableCountryCodes(availableCountries)
  .setDefaultCountryCode("by")
  .setCountryCodeWithBounds({
      ...mockData.bounds,
      by: {
        portrait: [ [20.6826, 46.7119], [36.5277, 60.0316] ],
        landscape: [ [14.3366, 49.9477], [42.3351, 57.4112] ],
      },
    })
  .build();