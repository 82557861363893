import { DirectiveBinding } from "vue";

function clickHandler(
  el: HTMLInputElement,
  binding: DirectiveBinding,
  e: PointerEvent
) {
  if (e.target !== el && !el.contains(e.target as Node)) {
    if (binding.value) {
      binding.value(e);
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("click-outside", {
    beforeMount: function (el, binding) {
      el.clickOutsideHandler = clickHandler.bind(null, el, binding);

      document.addEventListener("click", el.clickOutsideHandler);
    },
    beforeUnmount: function (el) {
      if (el.clickOutsideHandler) {
        document.removeEventListener("click", el.clickOutsideHandler);
      }
    },
  });
});
