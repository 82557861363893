<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M14.1327 18.4003L11.266 15.5337C11.0216 15.2892 10.7105 15.167 10.3327 15.167C9.9549 15.167 9.64379 15.2892 9.39935 15.5337C9.1549 15.7781 9.03268 16.0892 9.03268 16.467C9.03268 16.8448 9.1549 17.1559 9.39935 17.4003L13.1993 21.2003C13.466 21.467 13.7771 21.6003 14.1327 21.6003C14.4882 21.6003 14.7993 21.467 15.066 21.2003L22.5993 13.667C22.8438 13.4225 22.966 13.1114 22.966 12.7337C22.966 12.3559 22.8438 12.0448 22.5993 11.8003C22.3549 11.5559 22.0438 11.4337 21.666 11.4337C21.2882 11.4337 20.9771 11.5559 20.7327 11.8003L14.1327 18.4003ZM15.9993 29.3337C14.1549 29.3337 12.4216 28.9837 10.7993 28.2837C9.17713 27.5837 7.76602 26.6337 6.56602 25.4337C5.36602 24.2337 4.41602 22.8225 3.71602 21.2003C3.01602 19.5781 2.66602 17.8448 2.66602 16.0003C2.66602 14.1559 3.01602 12.4225 3.71602 10.8003C4.41602 9.1781 5.36602 7.76699 6.56602 6.56699C7.76602 5.36699 9.17713 4.41699 10.7993 3.71699C12.4216 3.01699 14.1549 2.66699 15.9993 2.66699C17.8438 2.66699 19.5771 3.01699 21.1993 3.71699C22.8216 4.41699 24.2327 5.36699 25.4327 6.56699C26.6327 7.76699 27.5827 9.1781 28.2827 10.8003C28.9827 12.4225 29.3327 14.1559 29.3327 16.0003C29.3327 17.8448 28.9827 19.5781 28.2827 21.2003C27.5827 22.8225 26.6327 24.2337 25.4327 25.4337C24.2327 26.6337 22.8216 27.5837 21.1993 28.2837C19.5771 28.9837 17.8438 29.3337 15.9993 29.3337Z"
      fill="#2954BB"
    />
  </svg>
</template>
