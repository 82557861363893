import { CookieOptionsKeys } from "~/types/entities";

export default defineNuxtPlugin(() => {
  const { cookiePolicyKey } = useRuntimeConfig().public;

  const cookie = getParsedCookie(cookiePolicyKey);
  if (!cookie) return;

  if (cookie[CookieOptionsKeys.Analytical]) {
    useGtag().initialize();
  }
});
