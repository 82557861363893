<template>
  <section class="section not-found-page">
    <div>
      <img
        :src="`/image/404-${brand}.svg`"
        alt="404"
        class="not-found-page__image"
      />
      <p class="text-h2">{{ $t("Oops!") }}</p>
      <p class="text-h2">{{ $t("Something went wrong...") }}</p>
      <p class="text-h2">{{ $t("Such a page does not exist.") }}</p>
      <LocLink to="/" class="not-found-page__link">
        {{ $t("Go to Home") }}
      </LocLink>
    </div>
    <div>
      <img src="/image/404-image.svg" alt="404" class="not-found-page__image" />
    </div>
  </section>
</template>

<script lang="ts">
import BaseButton from "~/components/button/BaseButton.vue";
import LocLink from "~/components/lang/LocLink.vue";

export default defineNuxtComponent({
  components: { LocLink, BaseButton },
  setup() {
    const { brand } = useRuntimeConfig().public;
    return { brand };
  },
});
</script>
