<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.4276 38.3642C7.12934 39.066 7.97292 39.4168 8.95833 39.4168H34.0417C35.0271 39.4168 35.8707 39.066 36.5724 38.3642C37.2741 37.6625 37.625 36.8189 37.625 35.8335V10.7502C37.625 9.76475 37.2741 8.92117 36.5724 8.21943C35.8707 7.5177 35.0271 7.16683 34.0417 7.16683H32.25V5.37516C32.25 4.86752 32.0783 4.442 31.7349 4.0986C31.3915 3.7552 30.966 3.5835 30.4583 3.5835C29.9507 3.5835 29.5252 3.7552 29.1818 4.0986C28.8384 4.442 28.6667 4.86752 28.6667 5.37516V7.16683H14.3333V5.37516C14.3333 4.86752 14.1616 4.442 13.8182 4.0986C13.4748 3.7552 13.0493 3.5835 12.5417 3.5835C12.034 3.5835 11.6085 3.7552 11.2651 4.0986C10.9217 4.442 10.75 4.86752 10.75 5.37516V7.16683H8.95833C7.97292 7.16683 7.12934 7.5177 6.4276 8.21943C5.72587 8.92117 5.375 9.76475 5.375 10.7502V35.8335C5.375 36.8189 5.72587 37.6625 6.4276 38.3642ZM34.0417 35.8335H8.95833V17.9168H34.0417V35.8335Z"
      fill="#00114E"
    />
    <path
      d="M25.9792 32.2502C24.725 32.2502 23.6649 31.8172 22.799 30.9512C21.933 30.0852 21.5 29.0252 21.5 27.771C21.5 26.5168 21.933 25.4568 22.799 24.5908C23.6649 23.7248 24.725 23.2918 25.9792 23.2918C27.2333 23.2918 28.2934 23.7248 29.1594 24.5908C30.0253 25.4568 30.4583 26.5168 30.4583 27.771C30.4583 29.0252 30.0253 30.0852 29.1594 30.9512C28.2934 31.8172 27.2333 32.2502 25.9792 32.2502Z"
      fill="#94A2BC"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
