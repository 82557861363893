<template>
  <svg viewBox="0 0 85 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.853 13.6353H31.9449L31.1993 15.3707H29.2031L32.955 7.27205H34.879L38.6429 15.3707H36.5986L35.853 13.6353ZM35.2397 12.2122L33.905 9.11161L32.5702 12.2122H35.2397Z"
      fill="#00114E"
    />
    <path
      d="M39.3839 6.78613H41.2598V15.3707H39.3839V6.78613Z"
      fill="#00114E"
    />
    <path
      d="M43.0009 6.78613H44.8768V15.3707H43.0009V6.78613Z"
      fill="#00114E"
    />
    <path
      d="M48.7943 15.4633C48.2572 15.4633 47.7321 15.4016 47.219 15.2782C46.706 15.147 46.2971 14.9851 45.9925 14.7922L46.6178 13.4965C46.9064 13.6739 47.2551 13.8204 47.664 13.9361C48.0728 14.0441 48.4737 14.0981 48.8665 14.0981C49.6602 14.0981 50.057 13.9091 50.057 13.5312C50.057 13.3538 49.9488 13.2265 49.7323 13.1494C49.5159 13.0722 49.1832 13.0067 48.7342 12.9527C48.2051 12.8756 47.7682 12.7869 47.4235 12.6866C47.0788 12.5863 46.7781 12.4089 46.5216 12.1544C46.2731 11.8999 46.1488 11.5373 46.1488 11.0669C46.1488 10.6735 46.2651 10.3264 46.4975 10.0256C46.738 9.71708 47.0828 9.47797 47.5317 9.30829C47.9887 9.1386 48.5258 9.05376 49.1431 9.05376C49.6 9.05376 50.053 9.10389 50.5019 9.20416C50.9589 9.29672 51.3357 9.42784 51.6323 9.59753L51.007 10.8817C50.4378 10.5732 49.8165 10.419 49.1431 10.419C48.7422 10.419 48.4416 10.473 48.2412 10.5809C48.0408 10.6889 47.9406 10.8277 47.9406 10.9974C47.9406 11.1903 48.0488 11.3252 48.2652 11.4024C48.4817 11.4795 48.8264 11.5528 49.2994 11.6222C49.8285 11.707 50.2614 11.7996 50.5981 11.8999C50.9348 11.9924 51.2274 12.166 51.476 12.4205C51.7245 12.675 51.8487 13.0298 51.8487 13.4849C51.8487 13.8705 51.7285 14.2138 51.488 14.5146C51.2475 14.8154 50.8947 15.0506 50.4298 15.2203C49.9728 15.3823 49.4277 15.4633 48.7943 15.4633Z"
      fill="#00114E"
    />
    <path
      d="M56.8302 9.05376C57.4314 9.05376 57.9765 9.18874 58.4656 9.45869C58.9626 9.72093 59.3514 10.095 59.632 10.5809C59.9126 11.0591 60.0529 11.6183 60.0529 12.2585C60.0529 12.8987 59.9126 13.4617 59.632 13.9477C59.3514 14.4259 58.9626 14.7999 58.4656 15.0699C57.9765 15.3321 57.4314 15.4633 56.8302 15.4633C56.0044 15.4633 55.3551 15.2126 54.8821 14.7113V17.6152H53.0062V9.14631H54.7979V9.86362C55.2629 9.32371 55.9403 9.05376 56.8302 9.05376ZM56.5055 13.9824C56.9865 13.9824 57.3793 13.8281 57.6839 13.5196C57.9966 13.2034 58.1529 12.783 58.1529 12.2585C58.1529 11.734 57.9966 11.3175 57.6839 11.009C57.3793 10.6928 56.9865 10.5347 56.5055 10.5347C56.0245 10.5347 55.6276 10.6928 55.315 11.009C55.0103 11.3175 54.858 11.734 54.858 12.2585C54.858 12.783 55.0103 13.2034 55.315 13.5196C55.6276 13.8281 56.0245 13.9824 56.5055 13.9824Z"
      fill="#00114E"
    />
    <path
      d="M64.3871 15.4633C63.7057 15.4633 63.0924 15.3283 62.5473 15.0583C62.0101 14.7807 61.5893 14.3989 61.2846 13.913C60.98 13.427 60.8277 12.8756 60.8277 12.2585C60.8277 11.6415 60.98 11.09 61.2846 10.6041C61.5893 10.1182 62.0101 9.74022 62.5473 9.47026C63.0924 9.19259 63.7057 9.05376 64.3871 9.05376C65.0685 9.05376 65.6778 9.19259 66.2149 9.47026C66.7521 9.74022 67.1729 10.1182 67.4776 10.6041C67.7822 11.09 67.9345 11.6415 67.9345 12.2585C67.9345 12.8756 67.7822 13.427 67.4776 13.913C67.1729 14.3989 66.7521 14.7807 66.2149 15.0583C65.6778 15.3283 65.0685 15.4633 64.3871 15.4633ZM64.3871 13.9824C64.8681 13.9824 65.2609 13.8281 65.5656 13.5196C65.8782 13.2034 66.0346 12.783 66.0346 12.2585C66.0346 11.734 65.8782 11.3175 65.5656 11.009C65.2609 10.6928 64.8681 10.5347 64.3871 10.5347C63.9061 10.5347 63.5093 10.6928 63.1966 11.009C62.884 11.3175 62.7276 11.734 62.7276 12.2585C62.7276 12.783 62.884 13.2034 63.1966 13.5196C63.5093 13.8281 63.9061 13.9824 64.3871 13.9824Z"
      fill="#00114E"
    />
    <path
      d="M70.9802 9.96775C71.2046 9.66694 71.5053 9.43941 71.8821 9.28515C72.2669 9.13089 72.7078 9.05376 73.2048 9.05376V10.7198C72.9964 10.7043 72.8561 10.6966 72.784 10.6966C72.2468 10.6966 71.826 10.8432 71.5213 11.1363C71.2167 11.4217 71.0644 11.8536 71.0644 12.4321V15.3707H69.1884V9.14631H70.9802V9.96775Z"
      fill="#00114E"
    />
    <path
      d="M78.7161 15.0699C78.5317 15.201 78.3032 15.3013 78.0307 15.3707C77.7661 15.4324 77.4855 15.4633 77.1889 15.4633C76.4193 15.4633 75.8221 15.2743 75.3972 14.8964C74.9803 14.5184 74.7719 13.9631 74.7719 13.2304V10.6735H73.7738V9.28515H74.7719V7.76954H76.6478V9.28515H78.2591V10.6735H76.6478V13.2072C76.6478 13.4695 76.7159 13.6739 76.8522 13.8204C76.9965 13.9592 77.1969 14.0286 77.4535 14.0286C77.7501 14.0286 78.0026 13.9515 78.211 13.7973L78.7161 15.0699Z"
      fill="#00114E"
    />
    <path
      d="M81.9457 15.4633C81.4086 15.4633 80.8835 15.4016 80.3704 15.2782C79.8573 15.147 79.4485 14.9851 79.1438 14.7922L79.7691 13.4965C80.0578 13.6739 80.4065 13.8204 80.8153 13.9361C81.2242 14.0441 81.625 14.0981 82.0178 14.0981C82.8115 14.0981 83.2083 13.9091 83.2083 13.5312C83.2083 13.3538 83.1001 13.2265 82.8837 13.1494C82.6672 13.0722 82.3345 13.0067 81.8856 12.9527C81.3565 12.8756 80.9196 12.7869 80.5748 12.6866C80.2301 12.5863 79.9295 12.4089 79.673 12.1544C79.4244 11.8999 79.3002 11.5373 79.3002 11.0669C79.3002 10.6735 79.4164 10.3264 79.6489 10.0256C79.8894 9.71708 80.2341 9.47797 80.6831 9.30829C81.14 9.1386 81.6771 9.05376 82.2944 9.05376C82.7514 9.05376 83.2043 9.10389 83.6533 9.20416C84.1102 9.29672 84.487 9.42784 84.7836 9.59753L84.1583 10.8817C83.5891 10.5732 82.9678 10.419 82.2944 10.419C81.8936 10.419 81.593 10.473 81.3925 10.5809C81.1921 10.6889 81.0919 10.8277 81.0919 10.9974C81.0919 11.1903 81.2001 11.3252 81.4166 11.4024C81.633 11.4795 81.9778 11.5528 82.4508 11.6222C82.9799 11.707 83.4128 11.7996 83.7495 11.8999C84.0862 11.9924 84.3788 12.166 84.6273 12.4205C84.8758 12.675 85.0001 13.0298 85.0001 13.4849C85.0001 13.8705 84.8798 14.2138 84.6393 14.5146C84.3988 14.8154 84.0461 15.0506 83.5811 15.2203C83.1242 15.3823 82.579 15.4633 81.9457 15.4633Z"
      fill="#00114E"
    />
    <path
      d="M10.6004 12.6357H0.0249023L1.03887 14.3834C2.59754 13.4887 4.59196 14.0155 5.49699 15.5792C6.39364 17.1346 5.8657 19.1248 4.29866 20.0279L5.31263 21.784L10.6004 12.6357Z"
      fill="#346AED"
    />
    <path
      d="M10.5755 11.4068L5.28773 2.26685L4.27376 4.01455C5.83242 4.91767 6.36873 6.90788 5.47208 8.46325C4.56705 10.0186 2.57263 10.5538 1.01397 9.65905L0 11.4068H10.5755Z"
      fill="#346AED"
    />
    <path
      d="M11.6312 10.7712L16.919 1.63135H14.891C14.891 3.42922 13.4329 4.88425 11.6312 4.88425C9.82955 4.88425 8.37145 3.42922 8.37145 1.63135H6.34351L11.6312 10.7712Z"
      fill="#346AED"
    />
    <path
      d="M12.7125 11.3651H23.2879L22.2739 9.61736C20.7153 10.5121 18.7209 9.9853 17.8158 8.42157C16.9192 6.86619 17.4471 4.87598 19.0142 3.97286L18.0002 2.2168L12.7125 11.3651Z"
      fill="#346AED"
    />
    <path
      d="M12.7374 12.5855L18.0251 21.7254L19.0391 19.9693C17.4804 19.0662 16.9441 17.076 17.8407 15.5206C18.7458 13.9653 20.7402 13.4301 22.2988 14.3249L23.3128 12.5771H12.7374V12.5855Z"
      fill="#346AED"
    />
    <path
      d="M11.6816 13.229L6.39386 22.3689H8.4218C8.4218 20.571 9.8799 19.116 11.6816 19.116C13.4833 19.116 14.9414 20.571 14.9414 22.3689H16.9693L11.6816 13.229Z"
      fill="#00114E"
    />
    <path
      d="M13.3157 1.63092C13.3157 2.53419 12.5867 3.26183 11.6816 3.26183C10.785 3.26183 10.0475 2.53419 10.0475 1.63092C10.0475 0.736003 10.7766 0 11.6816 0C12.5867 0 13.3157 0.72764 13.3157 1.63092Z"
      fill="#00114E"
    />
    <path
      d="M13.3157 22.369C13.3157 23.2722 12.5867 23.9999 11.6816 23.9999C10.785 23.9999 10.0475 23.2722 10.0475 22.369C10.0475 21.474 10.7766 20.738 11.6816 20.738C12.5867 20.738 13.3157 21.4657 13.3157 22.369Z"
      fill="#346AED"
    />
    <path
      d="M4.53324 16.9253C4.53324 17.8286 3.80419 18.5563 2.89916 18.5563C2.00251 18.5563 1.26508 17.8286 1.26508 16.9253C1.26508 16.0304 1.99413 15.2944 2.89916 15.2944C3.79581 15.2944 4.53324 16.0221 4.53324 16.9253Z"
      fill="#00114E"
    />
    <path
      d="M22.2989 16.9253C22.2989 17.8286 21.5698 18.5563 20.6648 18.5563C19.7681 18.5563 19.0307 17.8286 19.0307 16.9253C19.0307 16.0304 19.7598 15.2944 20.6648 15.2944C21.5614 15.2944 22.2989 16.0221 22.2989 16.9253Z"
      fill="#00114E"
    />
    <path
      d="M22.2989 6.83258C22.2989 7.73585 21.5698 8.46349 20.6648 8.46349C19.7681 8.46349 19.0307 7.73585 19.0307 6.83258C19.0307 5.93766 19.7598 5.20166 20.6648 5.20166C21.5614 5.21002 22.2989 5.93766 22.2989 6.83258Z"
      fill="#00114E"
    />
    <path
      d="M4.53324 6.83258C4.53324 7.73585 3.80419 8.46349 2.89916 8.46349C2.00251 8.46349 1.26508 7.73585 1.26508 6.83258C1.26508 5.93766 1.99413 5.20166 2.89916 5.20166C3.79581 5.21002 4.53324 5.93766 4.53324 6.83258Z"
      fill="#00114E"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
