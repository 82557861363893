<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25937 12.7483C8.13874 12.7486 8.01985 12.7194 7.91309 12.6632V14.2604H16.0876V12.6632C15.9809 12.7193 15.8622 12.7485 15.7417 12.7483H8.25937ZM7.91309 9.73926V11.3368C8.01985 11.2806 8.13874 11.2514 8.25937 11.2517H15.7417C15.8622 11.2514 15.981 11.2806 16.0876 11.3368V9.73926L7.91309 9.73926Z"
      fill="#00114E"
    />
    <path
      d="M6.56453 2.90332H6.55574C6.15399 2.90327 5.75616 2.98236 5.38497 3.13606C5.01378 3.28976 4.6765 3.51507 4.39239 3.79912C4.10827 4.08316 3.88289 4.42039 3.7291 4.79155C3.57531 5.1627 3.49614 5.56051 3.49609 5.96226V18.0377C3.49614 18.4395 3.57531 18.8373 3.7291 19.2084C3.88289 19.5796 4.10827 19.9168 4.39239 20.2009C4.6765 20.4849 5.01378 20.7102 5.38497 20.8639C5.75616 21.0176 6.15399 21.0967 6.55574 21.0967H6.56453C6.96627 21.0967 7.36407 21.0176 7.73524 20.8639C8.10641 20.7102 8.44366 20.4849 8.72775 20.2008C9.01183 19.9168 9.23718 19.5796 9.39093 19.2084C9.54468 18.8373 9.62382 18.4395 9.62383 18.0377V5.96226C9.62382 5.56053 9.54468 5.16273 9.39093 4.79158C9.23718 4.42043 9.01183 4.0832 8.72775 3.79915C8.44366 3.5151 8.10641 3.28979 7.73524 3.13608C7.36407 2.98237 6.96627 2.90328 6.56453 2.90332ZM7.91277 17.5628C7.91277 17.7404 7.87778 17.9163 7.8098 18.0805C7.74181 18.2446 7.64216 18.3937 7.51654 18.5194C7.39092 18.645 7.24179 18.7446 7.07766 18.8126C6.91353 18.8806 6.73761 18.9156 6.55996 18.9156C6.38231 18.9156 6.20639 18.8806 6.04226 18.8126C5.87813 18.7446 5.729 18.645 5.60338 18.5194C5.47776 18.3937 5.37811 18.2446 5.31012 18.0805C5.24214 17.9163 5.20715 17.7404 5.20715 17.5628V6.43723C5.20715 6.25957 5.24214 6.08366 5.31012 5.91953C5.37811 5.7554 5.47776 5.60626 5.60338 5.48064C5.729 5.35502 5.87813 5.25538 6.04226 5.18739C6.20639 5.11941 6.38231 5.08441 6.55996 5.08441C6.73761 5.08441 6.91353 5.11941 7.07766 5.18739C7.24179 5.25538 7.39092 5.35502 7.51654 5.48064C7.64216 5.60626 7.74181 5.7554 7.8098 5.91953C7.87778 6.08366 7.91277 6.25957 7.91277 6.43723V17.5628Z"
      fill="#00114E"
    />
    <path
      d="M2.76995 4.96289H2.61707C1.97064 4.96289 1.35068 5.21969 0.893582 5.67678C0.436483 6.13388 0.179688 6.75384 0.179688 7.40028V16.6001C0.179687 16.9202 0.242732 17.2372 0.365222 17.5329C0.487712 17.8286 0.667249 18.0973 0.893581 18.3236C1.11991 18.55 1.38861 18.7295 1.68433 18.852C1.98004 18.9745 2.29699 19.0375 2.61707 19.0375H2.76995C3.09004 19.0375 3.40698 18.9745 3.7027 18.852C3.99842 18.7295 4.26712 18.55 4.49345 18.3236C4.71978 18.0973 4.89932 17.8286 5.02181 17.5329C5.1443 17.2372 5.20734 16.9202 5.20734 16.6001V7.40028C5.20734 6.75384 4.95055 6.13388 4.49345 5.67678C4.03635 5.21969 3.41639 4.96289 2.76995 4.96289ZM3.52551 16.1604C3.52551 16.2697 3.50399 16.3779 3.46218 16.4788C3.42036 16.5798 3.35908 16.6715 3.28182 16.7488C3.20456 16.826 3.11285 16.8873 3.0119 16.9291C2.91096 16.9709 2.80277 16.9924 2.69351 16.9924C2.58426 16.9924 2.47607 16.9709 2.37512 16.9291C2.27418 16.8873 2.18246 16.826 2.10521 16.7488C2.02795 16.6715 1.96666 16.5798 1.92485 16.4788C1.88304 16.3779 1.86152 16.2697 1.86152 16.1604V7.83996C1.86152 7.7307 1.88304 7.62251 1.92485 7.52157C1.96666 7.42063 2.02795 7.32891 2.10521 7.25165C2.18246 7.17439 2.27418 7.11311 2.37512 7.0713C2.47607 7.02949 2.58425 7.00797 2.69351 7.00797C2.80277 7.00797 2.91096 7.02949 3.0119 7.0713C3.11285 7.11311 3.20456 7.17439 3.28182 7.25165C3.35908 7.32891 3.42036 7.42063 3.46218 7.52157C3.50399 7.62251 3.52551 7.7307 3.52551 7.83996V16.1604Z"
      fill="#00114E"
    />
    <path
      d="M17.4441 2.90332H17.4353C17.0335 2.90328 16.6357 2.98237 16.2646 3.13608C15.8934 3.28979 15.5561 3.5151 15.2721 3.79915C14.988 4.0832 14.7626 4.42043 14.6089 4.79158C14.4551 5.16273 14.376 5.56053 14.376 5.96226V18.0377C14.376 18.4395 14.4551 18.8373 14.6089 19.2084C14.7626 19.5796 14.988 19.9168 15.2721 20.2008C15.5561 20.4849 15.8934 20.7102 16.2646 20.8639C16.6357 21.0176 17.0335 21.0967 17.4353 21.0967H17.4441C17.8458 21.0967 18.2436 21.0176 18.6148 20.8639C18.986 20.7102 19.3233 20.4849 19.6074 20.2009C19.8915 19.9168 20.1169 19.5796 20.2707 19.2084C20.4245 18.8373 20.5037 18.4395 20.5037 18.0377V5.96226C20.5037 5.56051 20.4245 5.1627 20.2707 4.79155C20.1169 4.42039 19.8915 4.08316 19.6074 3.79912C19.3233 3.51507 18.986 3.28976 18.6148 3.13606C18.2436 2.98236 17.8458 2.90327 17.4441 2.90332ZM18.7923 17.5628C18.7923 17.9216 18.6498 18.2657 18.3961 18.5194C18.1424 18.7731 17.7983 18.9156 17.4395 18.9156C17.0807 18.9156 16.7366 18.7731 16.4829 18.5194C16.2292 18.2657 16.0867 17.9216 16.0867 17.5628V6.43723C16.0867 6.07844 16.2292 5.73435 16.4829 5.48064C16.7366 5.22694 17.0807 5.08441 17.4395 5.08441C17.7983 5.08441 18.1424 5.22694 18.3961 5.48064C18.6498 5.73435 18.7923 6.07844 18.7923 6.43723V17.5628Z"
      fill="#00114E"
    />
    <path
      d="M21.2304 4.96289H21.3832C22.0297 4.96289 22.6496 5.21969 23.1067 5.67678C23.5638 6.13388 23.8206 6.75384 23.8206 7.40028V16.6001C23.8206 16.9202 23.7576 17.2372 23.6351 17.5329C23.5126 17.8286 23.3331 18.0973 23.1067 18.3236C22.8804 18.55 22.6117 18.7295 22.316 18.852C22.0203 18.9745 21.7033 19.0375 21.3832 19.0375H21.2304C20.9103 19.0375 20.5933 18.9745 20.2976 18.852C20.0019 18.7295 19.7332 18.55 19.5069 18.3236C19.2805 18.0973 19.101 17.8286 18.9785 17.5329C18.856 17.2372 18.793 16.9202 18.793 16.6001V7.40028C18.793 6.75384 19.0498 6.13388 19.5069 5.67678C19.964 5.21969 20.5839 4.96289 21.2304 4.96289ZM20.4748 16.1604C20.4748 16.2697 20.4963 16.3779 20.5381 16.4788C20.5799 16.5798 20.6412 16.6715 20.7185 16.7488C20.7957 16.826 20.8875 16.8873 20.9884 16.9291C21.0893 16.9709 21.1975 16.9924 21.3068 16.9924C21.4161 16.9924 21.5242 16.9709 21.6252 16.9291C21.7261 16.8873 21.8178 16.826 21.8951 16.7488C21.9724 16.6715 22.0336 16.5798 22.0755 16.4788C22.1173 16.3779 22.1388 16.2697 22.1388 16.1604V7.83996C22.1388 7.7307 22.1173 7.62251 22.0755 7.52157C22.0336 7.42063 21.9724 7.32891 21.8951 7.25165C21.8178 7.17439 21.7261 7.11311 21.6252 7.0713C21.5242 7.02949 21.4161 7.00797 21.3068 7.00797C21.1975 7.00797 21.0893 7.02949 20.9884 7.0713C20.8875 7.11311 20.7957 7.17439 20.7185 7.25165C20.6412 7.32891 20.5799 7.42063 20.5381 7.52157C20.4963 7.62251 20.4748 7.7307 20.4748 7.83996V16.1604Z"
      fill="#00114E"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
