import revive_payload_client_4sVQNw7RlN from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/alexandergolovnya/projects/2303www/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/alexandergolovnya/projects/2303www/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/Users/alexandergolovnya/projects/2303www/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/Users/alexandergolovnya/projects/2303www/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/alexandergolovnya/projects/2303www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/Users/alexandergolovnya/projects/2303www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_UYiXMU8ZyN from "/Users/alexandergolovnya/projects/2303www/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import _0sentry_client_xzaPeUvBvQ from "/Users/alexandergolovnya/projects/2303www/plugins/0sentry.client.ts";
import clickOutside_ZB3Eg0wOVk from "/Users/alexandergolovnya/projects/2303www/plugins/clickOutside.ts";
import googleAnalytics_client_AE3XgnrlDf from "/Users/alexandergolovnya/projects/2303www/plugins/googleAnalytics.client.ts";
import primevueTooltip_95H5tDsMM9 from "/Users/alexandergolovnya/projects/2303www/plugins/primevueTooltip.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_client_UYiXMU8ZyN,
  _0sentry_client_xzaPeUvBvQ,
  clickOutside_ZB3Eg0wOVk,
  googleAnalytics_client_AE3XgnrlDf,
  primevueTooltip_95H5tDsMM9
]