<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M15.9993 17.867L19.866 21.7337C20.1105 21.9781 20.4216 22.1003 20.7993 22.1003C21.1771 22.1003 21.4882 21.9781 21.7327 21.7337C21.9771 21.4892 22.0993 21.1781 22.0993 20.8003C22.0993 20.4225 21.9771 20.1114 21.7327 19.867L17.866 16.0003L21.7327 12.1337C21.9771 11.8892 22.0993 11.5781 22.0993 11.2003C22.0993 10.8225 21.9771 10.5114 21.7327 10.267C21.4882 10.0225 21.1771 9.90033 20.7993 9.90033C20.4216 9.90033 20.1105 10.0225 19.866 10.267L15.9993 14.1337L12.1327 10.267C11.8882 10.0225 11.5771 9.90033 11.1993 9.90033C10.8216 9.90033 10.5105 10.0225 10.266 10.267C10.0216 10.5114 9.89935 10.8225 9.89935 11.2003C9.89935 11.5781 10.0216 11.8892 10.266 12.1337L14.1327 16.0003L10.266 19.867C10.0216 20.1114 9.89935 20.4225 9.89935 20.8003C9.89935 21.1781 10.0216 21.4892 10.266 21.7337C10.5105 21.9781 10.8216 22.1003 11.1993 22.1003C11.5771 22.1003 11.8882 21.9781 12.1327 21.7337L15.9993 17.867ZM15.9993 29.3337C14.1549 29.3337 12.4216 28.9837 10.7993 28.2837C9.17713 27.5837 7.76602 26.6337 6.56602 25.4337C5.36602 24.2337 4.41602 22.8225 3.71602 21.2003C3.01602 19.5781 2.66602 17.8448 2.66602 16.0003C2.66602 14.1559 3.01602 12.4225 3.71602 10.8003C4.41602 9.1781 5.36602 7.76699 6.56602 6.56699C7.76602 5.36699 9.17713 4.41699 10.7993 3.71699C12.4216 3.01699 14.1549 2.66699 15.9993 2.66699C17.8438 2.66699 19.5771 3.01699 21.1993 3.71699C22.8216 4.41699 24.2327 5.36699 25.4327 6.56699C26.6327 7.76699 27.5827 9.1781 28.2827 10.8003C28.9827 12.4225 29.3327 14.1559 29.3327 16.0003C29.3327 17.8448 28.9827 19.5781 28.2827 21.2003C27.5827 22.8225 26.6327 24.2337 25.4327 25.4337C24.2327 26.6337 22.8216 27.5837 21.1993 28.2837C19.5771 28.9837 17.8438 29.3337 15.9993 29.3337Z"
      fill="#94A0BC"
    />
  </svg>
</template>
