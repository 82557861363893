<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7075 29.582C18.364 29.5931 18.0488 29.56 17.7618 29.4827L9.22992 27.0668V14.4515H11.4749C11.657 14.4515 11.8351 14.4695 12.0093 14.5055C12.1834 14.5415 12.3589 14.587 12.5357 14.6421L21.4821 17.9639C21.8889 18.1122 22.2305 18.3773 22.5071 18.7592C22.7837 19.141 22.9219 19.5327 22.9219 19.9342C22.9219 20.3583 22.7768 20.7029 22.4865 20.9678C22.1961 21.2328 21.8558 21.3653 21.4654 21.3653H17.8857C17.7055 21.3653 17.5438 21.3518 17.4006 21.3247C17.2573 21.2977 17.1067 21.254 16.9488 21.1936L15.5126 20.6668C15.3029 20.5895 15.0959 20.5979 14.8915 20.6921C14.6872 20.7863 14.5575 20.9298 14.5024 21.1226C14.4526 21.3216 14.4691 21.5095 14.5517 21.6863C14.6343 21.8631 14.7751 21.9875 14.9741 22.0595L16.7973 22.7037C16.9268 22.7525 17.0891 22.7904 17.2843 22.8175C17.4794 22.8445 17.6529 22.8581 17.8046 22.8581H26.7466C27.5853 22.8581 28.2713 23.1309 28.8046 23.6766C29.3379 24.2222 29.6046 24.9259 29.6046 25.7878L19.6118 29.424C19.3524 29.5182 19.0509 29.5709 18.7075 29.582ZM1.91362 28.8052C1.37811 28.2723 1.11035 27.6313 1.11035 26.882V17.182C1.11035 16.4327 1.37811 15.7903 1.91362 15.2548C2.44913 14.7193 3.09152 14.4515 3.84079 14.4515C4.59007 14.4515 5.23114 14.7193 5.76398 15.2548C6.29683 15.7903 6.56325 16.4327 6.56325 17.182V26.882C6.56325 27.6313 6.29683 28.2723 5.76398 28.8052C5.23114 29.338 4.59007 29.6045 3.84079 29.6045C3.09152 29.6045 2.44913 29.338 1.91362 28.8052Z"
      fill="#00114E"
    />
    <path
      d="M21.5009 15.7726C21.1251 15.7726 20.7548 15.7035 20.3901 15.5653C20.0253 15.4271 19.6937 15.2199 19.3952 14.9436L15.8321 11.4791C15.1326 10.8018 14.5386 10.0523 14.0502 9.23052C13.5618 8.40876 13.3176 7.5148 13.3176 6.54862C13.3176 5.28389 13.7653 4.21444 14.6607 3.34029C15.5561 2.46613 16.6362 2.02905 17.9009 2.02905C18.6014 2.02905 19.2615 2.17108 19.881 2.45515C20.5006 2.7392 21.0406 3.12615 21.5009 3.61602C21.9613 3.12615 22.5013 2.7392 23.1209 2.45515C23.7404 2.17108 24.4005 2.02905 25.1009 2.02905C26.3657 2.02905 27.4458 2.46613 28.3412 3.34029C29.2366 4.21444 29.6843 5.28389 29.6843 6.54862C29.6843 7.5148 29.4443 8.40876 28.9643 9.23052C28.4844 10.0523 27.8947 10.8018 27.1952 11.4791L23.6068 14.9436C23.3082 15.2199 22.9766 15.4271 22.6118 15.5653C22.2471 15.7035 21.8768 15.7726 21.5009 15.7726Z"
      fill="#94A2BC"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "PhoneIcon",
};
</script>

<style scoped></style>
