<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.56097 25.4399C7.05602 25.9345 7.65115 26.1819 8.34635 26.1819H13.6698C14.0245 26.1819 14.3238 26.06 14.5678 25.8162C14.8118 25.5724 14.9338 25.2733 14.9338 24.9189C14.9338 24.5645 14.8118 24.2654 14.5678 24.0216C14.3238 23.7778 14.0245 23.6559 13.6698 23.6559H8.34635V8.27077H10.5222V10.4449C10.5222 10.7994 10.6442 11.0985 10.8882 11.3422C11.1322 11.586 11.4315 11.7079 11.7862 11.7079H20.3037C20.6584 11.7079 20.9577 11.586 21.2017 11.3422C21.4457 11.0985 21.5677 10.7994 21.5677 10.4449V8.27077H23.7436V12.4797C23.7436 12.8341 23.8656 13.1332 24.1095 13.377C24.3535 13.6208 24.6529 13.7427 25.0075 13.7427C25.3623 13.7427 25.6616 13.6208 25.9056 13.377C26.1496 13.1332 26.2716 12.8341 26.2716 12.4797V8.27077C26.2716 7.57612 26.024 6.98146 25.5289 6.4868C25.0339 5.99211 24.4388 5.74477 23.7436 5.74477H19.3846C19.1499 5.08382 18.7253 4.54295 18.1109 4.12215C17.4966 3.70136 16.8079 3.49097 16.045 3.49097C15.3082 3.49097 14.6296 3.70136 14.0092 4.12215C13.3888 4.54295 12.9634 5.08382 12.7331 5.74477H8.34635C7.65115 5.74477 7.05602 5.99211 6.56097 6.4868C6.0659 6.98146 5.81836 7.57612 5.81836 8.27077V23.6559C5.81836 24.3505 6.0659 24.9452 6.56097 25.4399ZM16.8367 7.85199C16.6237 8.0648 16.3598 8.1712 16.045 8.1712C15.7301 8.1712 15.4662 8.0648 15.2532 7.85199C15.0403 7.63918 14.9338 7.37548 14.9338 7.06089C14.9338 6.7463 15.0403 6.4826 15.2532 6.26979C15.4662 6.05697 15.7301 5.95057 16.045 5.95057C16.3598 5.95057 16.6237 6.05697 16.8367 6.26979C17.0497 6.4826 17.1561 6.7463 17.1561 7.06089C17.1561 7.37548 17.0497 7.63918 16.8367 7.85199Z"
      fill="#00114E"
    />
    <path
      d="M19.9341 21.4147L25.2618 16.0913C25.4921 15.8611 25.7824 15.7461 26.1326 15.7461C26.4829 15.7461 26.7732 15.8611 27.0035 16.0913C27.2338 16.3214 27.3478 16.6103 27.3456 16.9581C27.3434 17.3059 27.2271 17.5948 26.9968 17.8249L20.8249 23.9986C20.57 24.2517 20.2727 24.3782 19.9329 24.3782C19.5931 24.3782 19.2966 24.2517 19.0434 23.9986L15.9761 20.9338C15.7458 20.7037 15.6307 20.4147 15.6307 20.067C15.6307 19.7192 15.7458 19.4302 15.9761 19.2001C16.2064 18.97 16.4967 18.855 16.847 18.855C17.1972 18.855 17.4875 18.97 17.7178 19.2001L19.9341 21.4147Z"
      fill="#94A2BC"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
