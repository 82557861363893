<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
    <path
      d="M17.8001 16.0002L12.6001 10.8002C12.3556 10.5558 12.2334 10.2447 12.2334 9.86689C12.2334 9.48912 12.3556 9.17801 12.6001 8.93356C12.8445 8.68912 13.1556 8.56689 13.5334 8.56689C13.9112 8.56689 14.2223 8.68912 14.4667 8.93356L20.6001 15.0669C20.7334 15.2002 20.8278 15.3447 20.8834 15.5002C20.939 15.6558 20.9667 15.8225 20.9667 16.0002C20.9667 16.178 20.939 16.3447 20.8834 16.5002C20.8278 16.6558 20.7334 16.8002 20.6001 16.9336L14.4667 23.0669C14.2223 23.3113 13.9112 23.4336 13.5334 23.4336C13.1556 23.4336 12.8445 23.3113 12.6001 23.0669C12.3556 22.8225 12.2334 22.5113 12.2334 22.1336C12.2334 21.7558 12.3556 21.4447 12.6001 21.2002L17.8001 16.0002Z"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
