<template>
  <svg viewBox="0 0 140 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.94896 40H6.99858C6.34091 39.9916 5.68477 39.9357 5.03546 39.8327C4.43107 39.7318 3.84511 39.5439 3.29644 39.2751C2.20641 38.726 1.31858 37.8554 0.757442 36.7854C0.481292 36.2463 0.28999 35.6691 0.190069 35.0735C0.0816017 34.4339 0.0237436 33.787 0.0170149 33.1386C0.0170149 32.9212 0 32.1963 0 32.1963V7.79814C0 7.79814 2.70349e-07 7.0844 0.0170149 6.87533C0.0219242 6.22604 0.0788397 5.57813 0.187234 4.93762C0.285883 4.34 0.477232 3.76079 0.754606 3.22019C1.03036 2.68826 1.39149 2.20344 1.82411 1.78436C2.2562 1.35927 2.75291 1.00279 3.29645 0.727704C3.84388 0.458365 4.42899 0.270437 5.03261 0.170075C5.6836 0.0648704 6.34172 0.0080182 7.0014 0H133.01C133.664 0.00548299 134.317 0.0614183 134.962 0.167291C135.571 0.265518 136.162 0.453496 136.715 0.724891C137.808 1.27887 138.695 2.15676 139.252 3.23413C139.527 3.77066 139.718 4.34496 139.818 4.93761C139.928 5.58286 139.989 6.23518 140 6.88924V33.1386C139.99 33.7797 139.93 34.4191 139.822 35.0512C139.681 35.9443 139.326 36.7917 138.787 37.5233C138.247 38.2549 137.538 38.8497 136.718 39.2584C136.166 39.53 135.576 39.718 134.968 39.816C134.318 39.9196 133.662 39.9755 133.004 39.9833H7.94896V40Z"
      fill="#010101"
    />
    <path
      d="M51.7305 27.3144H46.7404L45.5291 30.5876H43.3362L48.2156 18.3592H50.2865L55.2766 30.5876H52.9816L51.7305 27.3144ZM47.3759 25.6416H51.0922L49.7617 22.1398C49.5801 21.6575 49.3986 21.1222 49.2227 20.5367C49.061 21.0553 48.8851 21.5822 48.6979 22.1231L47.3759 25.6416Z"
      fill="white"
    />
    <path
      d="M56.1589 23.7094C56.1589 23.0348 56.1362 22.2913 56.0908 21.479H58.0766C58.1512 21.8613 58.2024 22.2477 58.2298 22.6361C58.5773 22.2268 59.0107 21.8963 59.5004 21.6672C59.9901 21.4382 60.5244 21.3159 61.0667 21.3089C61.7025 21.2991 62.3269 21.4758 62.8601 21.8165C63.3932 22.1572 63.8109 22.6464 64.0596 23.2216C64.4086 24.0123 64.5744 24.8694 64.545 25.7308C64.5663 26.6701 64.3723 27.602 63.9774 28.4575C63.6469 29.1675 63.1073 29.7638 62.4284 30.1693C61.7299 30.566 60.9343 30.7675 60.1277 30.752C59.493 30.7516 58.8629 30.6461 58.2639 30.4398V34.212L56.1419 34.3904L56.1589 23.7094ZM61.7363 28.3571C62.1674 27.7996 62.3858 26.9631 62.3858 25.8479C62.3858 24.8312 62.2062 24.0877 61.8468 23.6175C61.6845 23.3989 61.4706 23.2223 61.2236 23.1029C60.9765 22.9834 60.7037 22.9246 60.4284 22.9316C60.032 22.9264 59.6409 23.0225 59.2936 23.2104C58.9247 23.4158 58.5891 23.6743 58.2979 23.9772V28.8562C58.533 28.9652 58.7807 29.0457 59.0355 29.096C59.3096 29.1543 59.5891 29.1842 59.8695 29.1852C60.2257 29.2062 60.5817 29.1417 60.9068 28.9975C61.232 28.8532 61.5167 28.6335 61.7363 28.3571Z"
      fill="white"
    />
    <path
      d="M65.8358 23.7094C65.8358 23.0348 65.813 22.2913 65.7675 21.479H67.7533C67.83 21.8611 67.8821 22.2476 67.9092 22.6361C68.2568 22.2269 68.6903 21.8964 69.1801 21.6674C69.6698 21.4383 70.2042 21.316 70.7465 21.3089C71.3823 21.2991 72.0067 21.4758 72.5398 21.8165C73.0728 22.1572 73.4904 22.6464 73.7391 23.2216C74.0881 24.0123 74.2538 24.8694 74.2241 25.7308C74.2504 26.669 74.0613 27.601 73.6708 28.4575C73.3397 29.168 72.7992 29.7644 72.1191 30.1693C71.4208 30.5665 70.6251 30.768 69.8184 30.752C69.1838 30.7516 68.5538 30.646 67.9549 30.4398V34.212L65.8326 34.3904L65.8358 23.7094ZM71.4216 28.3571C71.8525 27.7865 72.068 26.9501 72.0683 25.8479C72.0683 24.8312 71.8896 24.0877 71.5324 23.6175C71.3699 23.3989 71.1559 23.2223 70.9088 23.1028C70.6616 22.9834 70.3887 22.9246 70.1134 22.9316C69.7171 22.9269 69.3264 23.0229 68.9791 23.2104C68.6101 23.4158 68.2744 23.6742 67.9833 23.9772V28.8562C68.2187 28.9643 68.4662 29.0448 68.7208 29.096C68.9949 29.1543 69.2745 29.1842 69.5549 29.1852C69.911 29.2057 70.2668 29.141 70.5919 28.9968C70.917 28.8526 71.2017 28.6331 71.4216 28.3571Z"
      fill="white"
    />
    <path
      d="M78.1249 30.0105C77.2183 29.5219 76.4821 28.7768 76.0116 27.872C75.4969 26.8497 75.2438 25.7195 75.274 24.5793C75.2373 23.3595 75.531 22.152 76.1249 21.0804C76.6615 20.1504 77.4672 19.3983 78.4398 18.9197C79.462 18.4288 80.5873 18.1814 81.7248 18.1976C82.4147 18.1946 83.1024 18.2732 83.7731 18.4317C84.4005 18.5769 85.0095 18.7901 85.5889 19.0674L85.0382 20.6705C84.0348 20.1731 82.9309 19.9026 81.8073 19.8788C81.014 19.8657 80.2312 20.058 79.5374 20.4363C78.8794 20.8077 78.3506 21.3654 78.0199 22.0367C77.6398 22.8329 77.4551 23.7058 77.4808 24.5849C77.4668 25.3771 77.6074 26.1645 77.8949 26.9046C78.1563 27.5596 78.6158 28.1202 79.2115 28.5105C79.89 28.9278 80.681 29.1338 81.4807 29.1016C81.8954 29.1006 82.3092 29.066 82.7181 28.9984C83.1135 28.9366 83.502 28.8377 83.8781 28.7029V26.216H81.0015V24.5961H85.9406V29.7512C85.224 30.0793 84.4736 30.3307 83.7023 30.5012C82.9375 30.6743 82.1554 30.7622 81.3706 30.7633C80.2409 30.7899 79.1232 30.5307 78.1249 30.0105Z"
      fill="white"
    />
    <path
      d="M93.478 30.5876C93.398 30.1894 93.3487 29.7858 93.3306 29.3804C92.9763 29.7856 92.5434 30.1173 92.0571 30.3561C91.508 30.6294 90.8994 30.7672 90.2839 30.7576C89.7803 30.7639 89.2817 30.6581 88.8256 30.4482C88.3917 30.2484 88.029 29.9249 87.7847 29.5197C87.5136 29.0608 87.3788 28.5363 87.3957 28.0059C87.3796 27.6035 87.4593 27.203 87.6282 26.8362C87.7972 26.4693 88.0508 26.1462 88.3689 25.8925C89.0156 25.3646 90.0945 25.1016 91.6056 25.1035C92.1685 25.1032 92.7308 25.1424 93.288 25.2206V25.0421C93.288 24.2392 93.1141 23.6723 92.7663 23.3415C92.3502 22.9896 91.8101 22.8124 91.2623 22.848C90.7718 22.8539 90.2839 22.9194 89.8098 23.0431C89.3401 23.1545 88.8822 23.3095 88.4423 23.5059L88.108 21.9893C88.589 21.7875 89.0885 21.6315 89.5998 21.5237C90.2432 21.3807 90.9009 21.3097 91.5605 21.3118C92.2592 21.293 92.9544 21.4154 93.603 21.6714C94.1735 21.9192 94.6381 22.3551 94.9163 22.9037C95.2646 23.6382 95.4273 24.4445 95.3904 25.254V28.8785C95.3904 29.2503 95.413 29.8209 95.458 30.5904L93.478 30.5876ZM92.2072 28.9147C92.6161 28.728 92.9835 28.4637 93.288 28.1369V26.4641C92.8172 26.4094 92.3438 26.3787 91.8697 26.3721C90.9937 26.3721 90.3876 26.5115 90.0514 26.7903C89.8871 26.9265 89.7565 27.0978 89.6694 27.291C89.5824 27.4842 89.5412 27.6943 89.5489 27.9055C89.5338 28.0879 89.5586 28.2714 89.6217 28.4435C89.6848 28.6157 89.7848 28.7726 89.9148 28.9036C90.1915 29.1328 90.5468 29.2494 90.908 29.2298C91.3598 29.2231 91.8041 29.1153 92.2072 28.9147Z"
      fill="white"
    />
    <path
      d="M97.0297 28.4017V17.6399L99.1405 17.4726V28.0309C99.1108 28.324 99.1817 28.6185 99.3421 28.8673C99.4231 28.953 99.5229 29.0194 99.634 29.0615C99.745 29.1035 99.8644 29.1201 99.983 29.1099C100.175 29.1017 100.366 29.0699 100.55 29.0151L100.8 30.5095C100.563 30.5976 100.316 30.6612 100.065 30.6991C99.7946 30.7453 99.5203 30.7695 99.2455 30.7716C97.7685 30.7623 97.0299 29.9723 97.0297 28.4017Z"
      fill="white"
    />
    <path
      d="M102.31 28.4017V17.6399L104.423 17.4726V28.0309C104.393 28.3241 104.464 28.6187 104.624 28.8673C104.705 28.953 104.805 29.0194 104.916 29.0615C105.027 29.1035 105.147 29.1201 105.265 29.1099C105.458 29.1017 105.648 29.0699 105.833 29.0151L106.08 30.5095C105.843 30.5977 105.598 30.6612 105.348 30.6991C105.076 30.7454 104.801 30.7697 104.525 30.7716C103.05 30.7623 102.311 29.9723 102.31 28.4017Z"
      fill="white"
    />
    <path
      d="M114.76 26.2996H108.698C108.794 28.2084 109.719 29.1629 111.473 29.1629C111.923 29.1606 112.371 29.1082 112.809 29.0067C113.256 28.9045 113.693 28.7645 114.116 28.5885L114.582 30.016C113.49 30.5219 112.294 30.7755 111.087 30.7576C110.222 30.7822 109.365 30.5904 108.596 30.2001C107.93 29.8462 107.395 29.2936 107.07 28.622C106.71 27.8438 106.535 26.9952 106.559 26.1406C106.536 25.2452 106.721 24.3564 107.101 23.5422C107.425 22.8513 107.949 22.2695 108.607 21.8694C109.282 21.4787 110.054 21.2799 110.837 21.295C111.614 21.2681 112.381 21.4795 113.03 21.9001C113.616 22.2996 114.069 22.8588 114.335 23.5087C114.627 24.2168 114.772 24.9752 114.76 25.7392L114.76 26.2996ZM112.692 24.9752C112.696 24.3834 112.497 23.8075 112.125 23.3415C111.959 23.1582 111.753 23.0133 111.523 22.9169C111.293 22.8205 111.044 22.7751 110.794 22.7838C110.543 22.7787 110.293 22.8257 110.062 22.9218C109.83 23.0179 109.621 23.1609 109.45 23.3415C109.05 23.7995 108.805 24.3686 108.749 24.9697L112.692 24.9752Z"
      fill="white"
    />
    <path
      d="M116.366 23.768C116.366 23.269 116.34 22.505 116.295 21.4818H118.281C118.315 21.7355 118.346 22.0395 118.38 22.3879C118.414 22.7364 118.431 23.0264 118.443 23.2466C118.656 22.8914 118.899 22.5539 119.169 22.2374C119.403 21.9671 119.686 21.7413 120.003 21.571C120.336 21.3942 120.711 21.3049 121.089 21.3117C121.377 21.3049 121.663 21.3396 121.94 21.4149L121.674 23.2216C121.341 23.1271 120.993 23.1031 120.65 23.1512C120.308 23.1992 119.98 23.3183 119.688 23.5003C119.218 23.8433 118.809 24.2596 118.477 24.7327V30.5875H116.366V23.768Z"
      fill="white"
    />
    <path
      d="M123.121 34.3152C122.862 34.2741 122.607 34.2135 122.358 34.134L122.743 32.6145C122.922 32.6617 123.103 32.6999 123.285 32.7288C123.489 32.7645 123.696 32.7831 123.904 32.7846C124.849 32.7846 125.553 32.1907 126.014 31.003L126.153 30.6656L122.735 21.4819H125.004L126.741 26.6147C126.963 27.2749 127.143 27.9481 127.279 28.6304C127.441 27.9724 127.628 27.3145 127.847 26.6564L129.578 21.4819H131.713L128.309 30.7075C128.052 31.4505 127.703 32.1594 127.268 32.818C126.937 33.3154 126.484 33.7236 125.952 34.0057C125.381 34.2796 124.752 34.4135 124.117 34.3961C123.783 34.3974 123.45 34.3703 123.121 34.3152Z"
      fill="white"
    />
    <path
      d="M43.3362 6.71642H47.1093L47.0043 7.6142H44.4369V9.35107H46.8568V10.1875H44.4369V12.1113H47.1404L47.0468 13.023H43.3362V6.71642Z"
      fill="white"
    />
    <path
      d="M49.7164 9.80553L47.7306 6.71642H48.9902L50.3405 8.98589L51.7589 6.71642H52.9817L50.9959 9.76654L53.1434 13.023H51.8668L50.3377 10.5416L48.7802 13.023H47.5575L49.7164 9.80553Z"
      fill="white"
    />
    <path
      d="M53.9575 6.71642H55.688C56.3477 6.67433 57.0033 6.84568 57.5546 7.2043C57.7701 7.37087 57.9414 7.58613 58.054 7.83176C58.1666 8.07738 58.2172 8.34611 58.2015 8.61507C58.2135 8.99923 58.1151 9.37895 57.9178 9.71076C57.7252 10.0227 57.4442 10.2727 57.1092 10.4301C56.7371 10.603 56.3293 10.6889 55.9177 10.681C55.632 10.677 55.3472 10.6481 55.0667 10.5946V13.023H53.9575V6.71642ZM56.7376 9.53232C56.8587 9.4181 56.9531 9.27944 57.0143 9.12589C57.0755 8.97233 57.1021 8.80756 57.0922 8.64293C57.1067 8.48517 57.0813 8.32634 57.0185 8.18048C56.9557 8.03461 56.8573 7.9062 56.732 7.80657C56.4127 7.61546 56.0412 7.52588 55.6682 7.55006H55.0667V9.78047C55.2775 9.81779 55.4909 9.83922 55.705 9.84457C56.0769 9.87202 56.4457 9.7605 56.7376 9.53232Z"
      fill="white"
    />
    <path
      d="M59.2965 6.71642H60.4028V12.0918H63.0582L62.9646 13.023H59.2965V6.71642Z"
      fill="white"
    />
    <path
      d="M64.7434 12.7135C64.2784 12.4563 63.9027 12.0678 63.6652 11.5983C63.4072 11.0778 63.2798 10.5042 63.2936 9.92542C63.2801 9.32061 63.4164 8.72162 63.6908 8.18011C63.9423 7.69187 64.3379 7.28958 64.8258 7.02585C65.3717 6.74478 65.9826 6.60834 66.5984 6.62995C67.1808 6.6145 67.7571 6.7501 68.2693 7.02308C68.7315 7.28109 69.1042 7.6696 69.3391 8.13826C69.5921 8.6533 69.7174 9.21984 69.705 9.7916C69.7205 10.4032 69.5832 11.0091 69.305 11.5564C69.0511 12.043 68.6562 12.4447 68.1701 12.7107C67.6338 12.9911 67.0331 13.1313 66.4258 13.1177C65.8384 13.1323 65.2576 12.9928 64.7434 12.7135ZM67.6142 11.9412C67.927 11.7377 68.1701 11.4462 68.3118 11.1048C68.4714 10.7142 68.5496 10.2961 68.5417 9.87523C68.5489 9.46215 68.4797 9.05121 68.3375 8.66242C68.2103 8.32563 67.9829 8.03422 67.685 7.82599C67.3396 7.61827 66.9425 7.50835 66.5375 7.50835C66.1326 7.50835 65.7355 7.61827 65.3901 7.82599C65.0764 8.0398 64.8322 8.33797 64.6867 8.68476C64.5252 9.05935 64.4449 9.46289 64.4509 9.86967C64.4443 10.2887 64.5195 10.7052 64.6725 11.0964C64.8083 11.4543 65.0567 11.7603 65.3817 11.9699C65.7066 12.1795 66.0911 12.2818 66.4792 12.2618C66.882 12.2716 67.2782 12.1597 67.6142 11.9412Z"
      fill="white"
    />
    <path
      d="M70.9391 6.71637H72.9475C73.5359 6.6754 74.1214 6.82739 74.6125 7.14854C74.801 7.2939 74.951 7.48183 75.0498 7.69637C75.1486 7.91091 75.1933 8.14572 75.1799 8.38084C75.187 8.68659 75.1168 8.98929 74.9758 9.26189C74.8527 9.501 74.6808 9.71263 74.4708 9.88358C74.2831 10.0327 74.0724 10.1513 73.8466 10.2349L75.8325 13.0229H74.5416L72.8708 10.5137H72.0566V13.0229H70.9391V6.71637ZM72.6782 9.69955C72.9245 9.70481 73.1685 9.65226 73.39 9.54624C73.5895 9.45211 73.7578 9.30416 73.875 9.11965C73.9922 8.9228 74.0521 8.6981 74.0483 8.47005C74.0595 8.33846 74.0374 8.20618 73.9839 8.08504C73.9304 7.96391 73.8472 7.85769 73.7417 7.7759C73.4757 7.61226 73.164 7.53517 72.8509 7.55559H72.0483V9.71078L72.6782 9.69955Z"
      fill="white"
    />
    <path
      d="M76.6982 6.71642H80.4798L80.3716 7.6142H77.8183V9.35107H80.2157V10.1875H77.7957V12.1113H80.4991L80.4173 13.023H76.6982V6.71642Z"
      fill="white"
    />
    <path d="M84.5931 6.71642H85.6991V13.023H84.5931V6.71642Z" fill="white" />
    <path
      d="M88.6298 7.62533H86.644L86.7406 6.71642H91.7331L91.6314 7.62533H89.739V13.023H88.6298V7.62533Z"
      fill="white"
    />
    <path
      d="M96.5421 12.7135C96.0777 12.4563 95.7029 12.0677 95.4664 11.5983C95.2063 11.0784 95.0779 10.5045 95.0922 9.92542C95.0788 9.3206 95.2151 8.72162 95.4896 8.18011C95.7416 7.69249 96.1368 7.29042 96.6239 7.02592C97.1657 6.74359 97.7725 6.60337 98.3856 6.61882C98.9749 6.60324 99.5578 6.74281 100.074 7.02307C100.537 7.2802 100.909 7.669 101.143 8.13832C101.397 8.65299 101.524 9.21958 101.512 9.79159C101.528 10.4035 101.39 11.0097 101.11 11.5564C100.856 12.0434 100.461 12.4452 99.9746 12.7107C99.4383 12.9918 98.8373 13.132 98.2297 13.1178C97.6405 13.1334 97.0578 12.9938 96.5421 12.7135ZM99.4155 11.9412C99.7267 11.7366 99.9687 11.4453 100.111 11.1048C100.27 10.7141 100.348 10.296 100.34 9.87523C100.348 9.46216 100.278 9.05124 100.136 8.66247C100.01 8.3258 99.7836 8.03432 99.4864 7.82607C99.1406 7.6181 98.743 7.50804 98.3376 7.50804C97.9322 7.50804 97.5346 7.6181 97.1888 7.82607C96.8752 8.03987 96.631 8.33801 96.4854 8.68476C96.3252 9.05974 96.245 9.46302 96.2497 9.86967C96.2427 10.2888 96.3179 10.7053 96.4713 11.0964C96.6065 11.4403 96.8423 11.7372 97.1489 11.9496C97.4848 12.1674 97.8816 12.2765 98.2838 12.2618C98.6854 12.2699 99.0799 12.1581 99.4155 11.9412Z"
      fill="white"
    />
    <path
      d="M102.74 6.71642H104.011L107.186 11.6038C107.158 11.1382 107.146 10.7116 107.146 10.3185V6.71642H108.173V13.023H106.942L103.719 8.05742C103.75 8.61507 103.767 9.11972 103.767 9.55745V13.023H102.74V6.71642Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.7333 6.74984H15.9575C10.4056 6.74984 8.37447 8.74603 8.37447 14.2023V25.7837C8.37447 31.24 10.4056 33.2362 15.9575 33.2362H27.7333C33.2851 33.2362 35.3192 31.24 35.3192 25.7837V14.2023C35.3248 8.74603 33.2936 6.74984 27.7333 6.74984Z"
      fill="#C91430"
    />
    <path
      d="M13.7106 19.993H14.4254V23.4586H13.7106V22.0506H12.0964V23.4586H11.3815V19.993H12.0964V21.3871H13.7106V19.993ZM17.739 21.9753C17.739 22.533 17.4552 22.8396 16.9389 22.8396C16.4227 22.8396 16.1332 22.533 16.1332 21.9446V19.993H15.4183V21.9725C15.4183 22.9456 15.9687 23.5032 16.9276 23.5032C17.8865 23.5032 18.4538 22.9456 18.4538 21.9418V19.993H17.739V21.9753ZM26 22.374L25.1999 19.993H24.6127L23.8184 22.3768L23.0382 19.993H22.2779L23.5063 23.4586H24.0992L24.8964 21.1835L25.6964 23.4586H26.2949L27.5233 19.993H26.7801L26 22.374ZM28.8028 21.9642H30.1049V21.3369H28.8028V20.6287H30.6921V19.993H28.0993V23.4502H30.7602V22.8257H28.8028L28.8028 21.9642ZM31.6113 23.4502H32.3177V19.993H31.6113V23.4502ZM19.8666 22.7337L19.546 23.453H18.8169L20.3574 19.993H20.9872L22.5332 23.453H21.7957L21.4921 22.7365L19.8666 22.7337ZM20.1333 22.1092H21.2084L20.6694 20.8713L20.1333 22.1092Z"
      fill="white"
    />
    <path
      d="M21.8497 15.5851C20.6604 15.5844 19.52 15.1198 18.679 14.2933C17.838 13.4668 17.3653 12.3461 17.3645 11.1772H17.9972C17.9972 11.6744 18.0968 12.1668 18.2904 12.6261C18.484 13.0855 18.7678 13.5029 19.1256 13.8545C19.4833 14.206 19.908 14.4849 20.3754 14.6752C20.8428 14.8655 21.3438 14.9634 21.8497 14.9634C22.3556 14.9634 22.8566 14.8655 23.324 14.6752C23.7914 14.4849 24.2161 14.206 24.5738 13.8545C24.9315 13.5029 25.2153 13.0855 25.4089 12.6261C25.6025 12.1668 25.7022 11.6744 25.7022 11.1772H26.3348C26.334 12.3461 25.8613 13.4668 25.0203 14.2933C24.1793 15.1198 23.039 15.5844 21.8497 15.5851Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
