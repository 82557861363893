<template>
  <button
    class="btn"
    :class="buttonClass"
    :disabled="disabled"
    @focus="onFocus"
    @blur="onBlur"
  >
    <SpinnerRadial
      v-if="loading"
      class="btn-spinner"
      :theme="spinnerTheme"
      size="25"
    />
    <div class="btn-content" :class="{ visible: !loading }">
      <BaseIcon v-if="icon" class="btn__icon" :icon="icon" />
      <span :class="buttonTextClass">
        <slot />
      </span>
      <BaseIcon v-if="rightIcon" class="btn__rigth-icon" :icon="rightIcon" />
    </div>
  </button>
</template>

<script lang="ts">
import { PropType } from "vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import SpinnerRadial, {
  SPINNER_RADIAL_THEMES,
} from "~/components/loaders/SpinnerRadial.vue";

export default defineNuxtComponent({
  components: { SpinnerRadial, BaseIcon },

  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    outlined: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    small: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    text: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    icon: {
      type: String as PropType<string>,
    },
    rightIcon: {
      type: String as PropType<string>,
    },
    secondary: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props) {
    const isFocus = ref(false);

    const spinnerTheme = computed(() =>
      (props.outlined && !isFocus.value) || props.text
        ? SPINNER_RADIAL_THEMES.dark
        : SPINNER_RADIAL_THEMES.light
    );

    const buttonClass = computed(() => ({
      btn_loading: props.loading,
      btn_outlined: props.outlined,
      btn_text: props.text,
      btn_small: props.small,
      btn_secondary: props.secondary,
    }));

    const buttonTextClass = computed(() => ({
      "btn-text": true,
      "text-18-500": !props.small,
      "text-16-500": props.small,
    }));

    function onFocus() {
      isFocus.value = true;
    }

    function onBlur() {
      isFocus.value = false;
    }

    return {
      SPINNER_RADIAL_THEMES,
      buttonClass,
      buttonTextClass,
      spinnerTheme,
      onFocus,
      onBlur,
    };
  },
});
</script>
