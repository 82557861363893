<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useCountriesStore } from "~/stores/countries";
import { type Content, DEFAULT_CONTENT, useContentStore } from "./stores/content";
import { getStatistics } from "./api/statisticsRequest";
import type { OfficeContact } from "./types/content";

const { saveContent } = useContentStore();
const { currentCountry } = storeToRefs(useCountriesStore());

const { brand } = useRuntimeConfig().public;

const { data } = await useAsyncData("content", async () => {
  const [statistics, feedback, officeContactData, videoLinkData] =
    await Promise.all([
      getStatistics(),
      queryContent(
        `/sections/${brand}/${currentCountry.value.code}/feedback-section`
      ).findOne(),
      queryContent(
        `/sections/${brand}/${currentCountry.value.code}/contacts-section`
      ).findOne(),
      queryContent(
        `/sections/${brand}/${currentCountry.value.code}/video-section`
      ).findOne(),
    ]);

  const content: Content = {
    levels: statistics?.levels ?? DEFAULT_CONTENT.levels,
    companies: feedback?.companies ?? DEFAULT_CONTENT.companies,
    officeContact: (officeContactData?.officeContact as OfficeContact) ?? DEFAULT_CONTENT.officeContact,
    applinks: officeContactData?.appLinks ?? DEFAULT_CONTENT.applinks,
    socialNetworks: officeContactData?.socialNetworks ?? DEFAULT_CONTENT.socialNetworks,
    videoLink: videoLinkData?.videoLink ?? DEFAULT_CONTENT.videoLink,
  };

  return content;
});

data.value && saveContent(data.value);
</script>

<template>
  <NuxtLayout>
    <NuxtPage :content="data" />
  </NuxtLayout>
</template>
