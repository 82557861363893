<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M9.4334 16L14.6001 21.2C14.8445 21.4444 14.9723 21.75 14.9834 22.1166C14.9945 22.4833 14.8667 22.8 14.6001 23.0667C14.3556 23.3111 14.0445 23.4333 13.6667 23.4333C13.289 23.4333 12.9778 23.3111 12.7334 23.0667L6.60007 16.9333C6.46673 16.8 6.37229 16.6555 6.31673 16.5C6.26118 16.3444 6.2334 16.1778 6.2334 16C6.2334 15.8222 6.26118 15.6555 6.31673 15.5C6.37229 15.3444 6.46673 15.2 6.60007 15.0667L12.7334 8.93332C12.9778 8.68887 13.2834 8.56109 13.6501 8.54998C14.0167 8.53887 14.3334 8.66665 14.6001 8.93332C14.8445 9.17776 14.9667 9.48887 14.9667 9.86665C14.9667 10.2444 14.8445 10.5555 14.6001 10.8L9.4334 16ZM18.2334 16L23.4001 21.2C23.6445 21.4444 23.7723 21.75 23.7834 22.1166C23.7945 22.4833 23.6667 22.8 23.4001 23.0667C23.1556 23.3111 22.8445 23.4333 22.4667 23.4333C22.089 23.4333 21.7778 23.3111 21.5334 23.0667L15.4001 16.9333C15.2667 16.8 15.1723 16.6555 15.1167 16.5C15.0612 16.3444 15.0334 16.1778 15.0334 16C15.0334 15.8222 15.0612 15.6555 15.1167 15.5C15.1723 15.3444 15.2667 15.2 15.4001 15.0667L21.5334 8.93332C21.7778 8.68887 22.0834 8.56109 22.4501 8.54998C22.8167 8.53887 23.1334 8.66665 23.4001 8.93332C23.6445 9.17776 23.7667 9.48887 23.7667 9.86665C23.7667 10.2444 23.6445 10.5555 23.4001 10.8L18.2334 16Z"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
