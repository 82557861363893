<template>
  <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.06559 6.61355L3.42942 3.71898C3.4862 3.64959 3.55376 3.59452 3.62819 3.55693C3.70262 3.51935 3.78246 3.5 3.86309 3.5C3.94373 3.5 4.02356 3.51935 4.098 3.55693C4.17243 3.59452 4.23998 3.64959 4.29677 3.71898C4.41053 3.85768 4.47439 4.04531 4.47439 4.24089C4.47439 4.43647 4.41053 4.6241 4.29677 4.7628L2.12229 7.42047H14.5767C14.7387 7.42047 14.894 7.49847 15.0086 7.6373C15.1231 7.77613 15.1875 7.96443 15.1875 8.16077C15.1875 8.35711 15.1231 8.54541 15.0086 8.68424C14.894 8.82307 14.7387 8.90107 14.5767 8.90107H2.08564L4.29677 11.5735C4.35402 11.6424 4.39946 11.7242 4.43047 11.8145C4.46148 11.9047 4.47744 12.0014 4.47744 12.0992C4.47744 12.1969 4.46148 12.2936 4.43047 12.3839C4.39946 12.4741 4.35402 12.556 4.29677 12.6248C4.23998 12.6942 4.17243 12.7492 4.098 12.7868C4.02356 12.8244 3.94373 12.8437 3.86309 12.8437C3.78246 12.8437 3.70262 12.8244 3.62819 12.7868C3.55376 12.7492 3.4862 12.6942 3.42942 12.6248L1.06559 9.75241C0.722432 9.33599 0 8.49682 0 8.26488C0 8.03293 0.722432 7.02997 1.06559 6.61355Z"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
