<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1991_2959)">
      <path
        d="M15.8048 14.8621L11.8254 10.8828C12.9098 9.5565 13.443 7.86416 13.3147 6.15581C13.1863 4.44745 12.4062 2.85379 11.1358 1.70445C9.8654 0.555106 8.20183 -0.0619731 6.48919 -0.0191518C4.77656 0.0236694 3.1459 0.723114 1.93451 1.93451C0.723114 3.1459 0.0236694 4.77656 -0.0191518 6.48919C-0.0619731 8.20183 0.555106 9.8654 1.70445 11.1358C2.85379 12.4062 4.44745 13.1863 6.15581 13.3147C7.86416 13.443 9.5565 12.9098 10.8828 11.8254L14.8621 15.8048C14.9878 15.9262 15.1562 15.9934 15.331 15.9919C15.5058 15.9904 15.673 15.9203 15.7966 15.7966C15.9203 15.673 15.9904 15.5058 15.9919 15.331C15.9934 15.1562 15.9262 14.9878 15.8048 14.8621ZM6.66677 12.0001C5.61193 12.0001 4.58079 11.6873 3.70372 11.1013C2.82666 10.5152 2.14308 9.68228 1.73941 8.70774C1.33574 7.7332 1.23012 6.66085 1.43591 5.62628C1.6417 4.59172 2.14965 3.64141 2.89553 2.89553C3.64141 2.14965 4.59172 1.6417 5.62628 1.43591C6.66085 1.23012 7.7332 1.33574 8.70774 1.73941C9.68228 2.14308 10.5152 2.82666 11.1013 3.70372C11.6873 4.58079 12.0001 5.61193 12.0001 6.66677C11.9985 8.08077 11.4361 9.4364 10.4362 10.4362C9.4364 11.4361 8.08077 11.9985 6.66677 12.0001Z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {};
</script>
