<script setup lang="ts">
const props = defineProps<{ to: string; target?: string }>();
const localePath = useLocalePath();

function scrollUp() {
  const top = document.getElementById("anchor-top");

  top?.scrollIntoView({ behavior: "smooth" });
}

function checkTargetAttribute(): boolean {
  return !!props.target;
}
</script>

<template>
  <NuxtLink
    :to="localePath(props.to)"
    :target="target"
    v-on="{ click: checkTargetAttribute() ? null : scrollUp }"
    :prefetch="false"
  >
    <slot />
  </NuxtLink>
</template>
