<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
  >
    <path
      d="M28.6667 34.0418C27.6812 34.0418 26.8377 33.691 26.1359 32.9892C25.4342 32.2875 25.0833 31.4439 25.0833 30.4585V12.5418C25.0833 11.5564 25.4342 10.7128 26.1359 10.0111C26.8377 9.30936 27.6812 8.9585 28.6667 8.9585C29.6521 8.9585 30.4957 9.30936 31.1974 10.0111C31.8991 10.7128 32.25 11.5564 32.25 12.5418V30.4585C32.25 31.4439 31.8991 32.2875 31.1974 32.9892C30.4957 33.691 29.6521 34.0418 28.6667 34.0418ZM14.3333 34.0418C13.3479 34.0418 12.5043 33.691 11.8026 32.9892C11.1009 32.2875 10.75 31.4439 10.75 30.4585V12.5418C10.75 11.5564 11.1009 10.7128 11.8026 10.0111C12.5043 9.30936 13.3479 8.9585 14.3333 8.9585C15.3187 8.9585 16.1623 9.30936 16.8641 10.0111C17.5658 10.7128 17.9167 11.5564 17.9167 12.5418V30.4585C17.9167 31.4439 17.5658 32.2875 16.8641 32.9892C16.1623 33.691 15.3187 34.0418 14.3333 34.0418Z"
      fill="#00114E"
    />
  </svg>
</template>

<script lang="ts">
export default {};
</script>
